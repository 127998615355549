// Libraries
import { configureStore } from "@reduxjs/toolkit";

// Slices
import authReducer from "./Features/authSlice";
import planReducer from "./Features/planSlice";
import questionnerReducer from "./Features/QuestionnerSlice";
import reportReducer from "./Features/reportSlice";

const store = configureStore({
    reducer: {
        auth: authReducer,
        plan: planReducer,
        questionner: questionnerReducer,
        report: reportReducer,
    },
});

export default store;