import React, { useState, useRef, useEffect } from "react";
import html2canvas from "html2canvas";
import "../assets/styles/components/screenshot.scss";
import { message } from "antd";
import axios from "axios";
import { BugOutlined } from '@ant-design/icons';
import { useSelector } from "react-redux";

const ScreenShot = ({ children }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [description, setDescription] = useState("");
  const [isDrawing, setIsDrawing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const contentRef = useRef(null);
  const canvasRef = useRef(null);
  const User = useSelector((state) => state.auth.user);

  console.log(User);
  useEffect(() => {
    if (capturedImage && canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
      };
      img.src = capturedImage;
    }
  }, [capturedImage]);

  const captureScreenshot = () => {
    // Get the dimensions of the contentRef (child component)
    const contentRect = contentRef.current.getBoundingClientRect();

    // Get the current scroll position
    const scrollX = window.scrollX;
    const scrollY = window.scrollY;

    // Set the width and height based on the window's current view (including scroll position)
    const captureWidth = window.innerWidth;
    const captureHeight = window.innerHeight;

    // Capture only the visible portion of the page (taking scrolling into account)
    html2canvas(contentRef.current, {
      useCORS: true,
      logging: false,
      scale: window.devicePixelRatio, // Optional: to handle high-DPI screens
      x: scrollX, // Capture from the current horizontal scroll position
      y: scrollY, // Capture from the current vertical scroll position
      width: captureWidth, // Set the width of the captured area to match the visible window
      height: captureHeight, // Set the height of the captured area to match the visible window
    }).then((canvas) => {
      const imageData = canvas.toDataURL("image/png");
      setCapturedImage(imageData);
      setShowPopup(true);
    });
  };

  const startDrawing = (e) => {
    setIsDrawing(true);
    draw(e);
  };

  const stopDrawing = () => {
    setIsDrawing(false);
    canvasRef.current.getContext("2d").beginPath();
  };

  const draw = (e) => {
    if (!isDrawing || !canvasRef.current) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    const x = (e.clientX - rect.left) * scaleX;
    const y = (e.clientY - rect.top) * scaleY;

    ctx.lineWidth = 4;
    ctx.lineCap = "round";
    ctx.strokeStyle = "red";

    ctx.lineTo(x, y);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x, y);
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = canvasRef.current.toDataURL();
    link.download = "edited_screenshot.png";
    link.click();
  };

  const handleSubmit = async () => {
    if (!description.trim()) {
      // If description is empty or only contains spaces
      message.error("Please enter a description before submitting.");
      return; // Prevent submission
    }
  
    setIsSubmitting(true);
    try {
      // Get the userName from localStorage, or set to "Anonymous" if not found
      let userName = User?.USR_First_Name + " " + User?.USR_Last_Name || "Anonymous";

      // Check if userName is "Anonymous", then fetch IP address
      if (userName === "Anonymous") {
        const ipResponse = await axios.get('https://api.ipify.org?format=json');
        const ipAddress = ipResponse.data.ip;
        userName = ipAddress; // Set userName to IP address
      }
    
      const payload = {
        userName,
        description,
        image: canvasRef.current.toDataURL(),
      };
    
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/bug-report`,
        payload
      );
    
      console.log("Bug report submitted successfully:", response.data);
      message.success("Bug report submitted successfully");
      setShowPopup(false);
    } catch (error) {
      alert("Failed to submit screenshot. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="screenshot-capture">
      <div ref={contentRef}>{children}</div>
      <button
        className="capture-btn"
        onClick={captureScreenshot}
        aria-label="Capture Screenshot"
      >
       <BugOutlined className="bugicon"/>
      </button>
      {showPopup && (
        <div className="popup">
          <div className="popup-card">
            <h2 className="form-title">
              <>Report Bug / Request Feature</>
              <span className="note">Simply click and drag to draw directly on the image!</span>
            </h2>
            <div className="canvas-container">
              <canvas
                ref={canvasRef}
                onMouseDown={startDrawing}
                onMouseUp={stopDrawing}
                onMouseMove={draw}
                onMouseLeave={stopDrawing}

              />
             
            </div>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Add a description..."
              className="description-input"
            />
            <div className="button-group">
              <button className="close-btn" onClick={() => setShowPopup(false)}>
                Close
              </button>
              {/* <button className="download-btn" onClick={handleDownload}>
                Download
              </button> */}
              <button
                className="submit-btn"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ScreenShot;
