// Libraries
import React from 'react'

// Context
import { useSetupContext } from '../../Context/index';

const OneSelectionTool = () => {
    // Context
    const { currentQuestion, currentResponses, handleCurrentResponse, PushQuestions, PopQuestions, PushDropdown, PopDropdown } = useSetupContext();

    const handle_Response = (response) => { 
        let Response = currentResponses;
        const isSelected = Response?.Response === response.choice;

        const previousOption = currentQuestion.options.find((option) => option.choice === Response?.Response)
        if(previousOption) {
            if(Array.isArray(previousOption?.followupQuestions) && previousOption?.followupQuestions.length > 0) {
                PopQuestions(previousOption.followupQuestions);
            }
            if(Array.isArray(previousOption?.listQuestions) && previousOption?.listQuestions.length > 0) {
                PopDropdown(previousOption.listQuestions);
            }
        }

        let updatedResponse;
        if(isSelected) {
            updatedResponse = { Response: "" }
        } else {
            updatedResponse = { Response: response.choice }

            PushQuestions(response?.followupQuestions);
            PushDropdown(response?.listQuestions);
        }

        handleCurrentResponse(updatedResponse);
    };

    return (
        <div className="d-flex w-100 flex-wrap gap-1">
            {currentQuestion.options.map((option, index) => (
                <div className="mb-2" key={index}>
                    <button
                        className={`btn btn-choice ${
                            currentResponses?.Response === option.choice ? "selected" : ""
                        }`}
                        onClick={() => handle_Response(option)}
                    >
                        {option.choice}
                    </button>
                </div>
            ))}
        </div>
    )
}

export default OneSelectionTool