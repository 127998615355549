// Libraries
import React from "react";
import { DatePicker } from "antd";
import moment from "moment";
// Context
import { useSetupContext } from "../../Context/index";


const AssetsInputTool = () => {
  // Context
  const { currentResponses, handleCurrentResponse } = useSetupContext();

  const handleInput = (field, value) => {
    let updatedResponse = currentResponses?.Response
    ? structuredClone(currentResponses)
    : { Response: { Value: "", Date: null }};

    if (field === 'Date') {
      value = value ? value.toISOString() : null;
    }

    updatedResponse.Response[field] = value;
    handleCurrentResponse(updatedResponse);
  };

  return (
    <div className="d-flex w-full align-items-center mb-2 gap-1">
    <div className="d-flex  align-items-center input-div ">
    <span className="Q8-dollar-icon">$</span>
        <input
          type="number"
          inputMode="numeric"
          pattern="[0-9]*"
          value={currentResponses?.Response?.Value || ""}
          onChange={(e) => handleInput("Value", e.target.value)}
          placeholder={"Enter Cost"}
          className=" w-32 px-3 py-2 border border-gray-300  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          required
        />
      </div>

      <span className="span-input">Accrued</span>
      <DatePicker
        value={currentResponses?.Response?.Date ? moment(currentResponses.Response.Date) : null} // Ensure the date is correctly parsed
        onChange={(date) => handleInput("Date", date)}
        className={`w-full appearance-none bg-white px-3 py-2 rounded-lg border transition-all duration-300 ease-in-out  focus:outline-none date-input focus:ring-2 focus:ring-blue-500 focus:border-transparent `}
        required
    
        picker="month"
         placeholder="MM-YYYY"
        format="MM-YYYY"
      />
     
    </div>
  )
}

export default AssetsInputTool