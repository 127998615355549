// Librairies
import React from 'react'

// Components
import VariableInput from '../Common/VariableInput'

// Context
import { useSetupContext } from '../../Context';

const VariableInputTool = ({Index, Question, Responses}) => {
  // Context
  const { handleCurrentDropdownResponse } = useSetupContext();

  // Handle Input Variable
  const handleInputVariable = (value) => {
    let Response = {...Responses};
    if(!Response){
      Response = {
        Response: [],
      }
    }

    Response.Response = value;
    handleCurrentDropdownResponse(Index, Response);
  };

  return (
    <div> 
      <VariableInput
        Data={Responses?.Response}
        onDataChange={(value) => handleInputVariable(value)}
      />
    </div>
  )
}

export default VariableInputTool