// Libraries
import React, { useState, useEffect } from 'react'

// Components
import { Spin } from 'antd';

// Context
import { useSetupContext } from '../../Context/index';

const DynamicSelectionTool = () => {
  // Context
  const { currentQuestion, currentResponses, handleCurrentResponse, GetDynamicOptions, PushQuestions, PopQuestions } = useSetupContext();

  // Custom States
  const [Options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSelection = (response) => {
    let Response = Array.isArray(currentResponses) ? currentResponses : [];
    const isSelected = Array.isArray(Response) && Response.find((res) => res.Response === response);

    let updatedResponse;
    if (isSelected) {
      updatedResponse = Response.filter((res) => res.Response !== response);

      if(currentQuestion.followuptype === "Repeated"){
        const QuestionsPop = currentQuestion.followupQuestions.map((question) => ({
          ...question,
          Keyelement: response,
          ...(currentQuestion.Keyelement && { FactorKey: currentQuestion.Keyelement })
        }));

        PopQuestions(QuestionsPop);
      } else {
        if(updatedResponse.length === 0){
          PopQuestions(currentQuestion.followupQuestions);
        }
      }

    } else {
      updatedResponse = [...Response, { Response: response }];

      if(currentQuestion.followuptype === "Repeated"){
        const QuestionsPush = currentQuestion.followupQuestions.map((question) => ({
          ...question,
          Keyelement: response,
          ...(currentQuestion.Keyelement && { FactorKey: currentQuestion.Keyelement })
        }));

        PushQuestions(QuestionsPush);
      } else {
        if(Response.length === 0){
          PushQuestions(currentQuestion.followupQuestions);
        }
      }
    }

    handleCurrentResponse(updatedResponse);
  };

  useEffect(() => {
    const handleOptions = async () => {
      try {
        setLoading(true);
        const result = await GetDynamicOptions();

        if(Array.isArray(currentResponses) && currentResponses.length > 0){
          currentResponses.forEach((res) => {
            if(!result.includes(res.Response)){
              PopQuestions(currentQuestion.followupQuestions.map(question => ({
                ...question,
                Keyelement: res.Response,
                ...(currentQuestion.Keyelement && { FactorKey: currentQuestion.Keyelement })
              })));

              currentResponses.filter((resp) => resp.Response !== res.Response);
            }
          });
        }

        setOptions(result);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };
    
    handleOptions();
  }, [currentQuestion]);

  return (
    <div className="d-flex w-100 flex-wrap gap-1">
      <div>
        {loading && <Spin />}
      </div>
      <div className="d-flex w-100 flex-wrap gap-1">
        {Options.map((option, index) => (
          <div className="mb-2" key={index}>
            <button
              className={`btn btn-choice ${
                Array.isArray(currentResponses) && currentResponses.find((res) => res.Response === option) ? "selected" : ""
              }`}
              onClick={() => handleSelection(option)}
            >
              {option}
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

export default DynamicSelectionTool