// Libraries
import React, { useState } from 'react'

// Tools
import VariableInputTool from './VariableInputTool';

// Context
import { useSetupContext } from '../../Context/index';

const SalaryInputTool = ({Index, Question, Responses}) => {
    // Context
    const { handleCurrentDropdownResponse } = useSetupContext();
const [selected, setSelected] = useState( "Per Year") 
    // Functions
    const handleInput = (field, value) => {
        setSelected(value)
        let Response = Responses ? structuredClone(Responses) : {
            Response: { Value: '', Type: '' },
            VariableInputFlag: false
        };
        console.log(Response , field, value);

        Response.Response[field] = value;
        handleCurrentDropdownResponse(Index, Response);
    }

    const handleVariable = () => {
        let Response = Responses ? structuredClone(Responses) : {
            Response: { Value: '', Type: '' },
            VariableInputFlag: false
        };

        Response.VariableInputFlag = !Response.VariableInputFlag;
        if (Response.VariableInputFlag) {
            Response.Response = [];
        } else {
            Response.Response = { Value: '', Type: '' };
        }

        handleCurrentDropdownResponse(Index, Response);
    };

    const handleInputVariable = (value) => {
       
        let Response = {...Responses};
        if(!Response){
            Response = {
                Response: [],
                VariableInputFlag: true
            }
        }

        Response.Response = value;
        handleCurrentDropdownResponse(Index, Response);
    };

    return (
        <div className="d-flex align-items-center gap-2">
            {!Responses?.VariableInputFlag && (
                <div className="d-flex w-100 flex-wrap gap-1">
                    <div className="d-flex align-items-center input-div">
                    <span className="Q8-dollar-icon">$</span>

                        <input type="number" inputMode="numeric" pattern="[0-9]*" className=" w-32 px-3 py-2 border border-gray-300  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" placeholder='e.g. 30000' style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0', borderTopRightRadius: '0.25rem', borderBottomRightRadius: '0.25rem' }} value={Responses?.Response?.Value || ""} onChange={(e) => handleInput("Value", e.target.value)} />
                    </div>
                
                    {/* <select className="form-select" value={Responses?.Response?.Type || ""} onChange={(e) => handleInput("Type", e.target.value)} required>
                        <option value="" disabled>Select a period</option>
                        <option value="Per Month">Per Month</option>
                        <option value="Per Year">Per Year</option>
                    </select> */}

<div className="toggle-container">
      <button
        className={`toggle-button ${Responses?.Response?.Type === 'Per Month' ? 'active' : ''}`}
        onClick={() => handleInput("Type", 'Per Month') }
      >
        Per Month
      </button>
      <button
        className={`toggle-button ${Responses?.Response?.Type === 'Per Year' ? 'active' : ''}`}
        onClick={() => handleInput("Type", 'Per Year')}
      >
        Per Year
      </button>
    </div>
                    
                
                </div>
            )}
            {Question.VariableEnable === "true" && (
                <div className="d-flex justify-content-center gap-2" style={{display: "flex" , flexDirection:"column"}}>
                    <label className="toggle-switch">
            <input
              type="checkbox"
              checked={Responses?.VariableInputFlag || false}
              onChange={() =>  handleVariable()}
          
            />
            <span className="toggle-slider">
              <span className="toggle-knob" />
            </span>
            <span className="toggle-label">Variable Input</span>
          </label>
                    {/* <div tyle={{display: "flex", flexDirection: "row"}}>
                        <div className="form-check form-switch p-0 mt-3">
                        <input
                            id="flexSwitchCheckDefault"
                            type="checkbox"
                            className='form-check-input ms-0 me-1'
                            checked={Responses?.VariableInputFlag || false}
                            onChange={() => handleVariable()}
                            style={{marginLeft: "10px"}}
                        />
                        <label className='form-check-label' htmlFor="flexSwitchCheckDefault">Variable Input</label>
                        </div>
                    </div> */}

                    {Responses?.VariableInputFlag && (
                        <VariableInputTool
                            Data={Responses?.Response}
                            onDataChange={(value) => handleInputVariable(value)}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default SalaryInputTool