// Libraries
import React from 'react'

// Tools
import VariableInput from '../Common/VariableInput';

// Context
import { useSetupContext } from '../../Context/index';

const NumberInputTool = ({Index, Question, Responses}) => {
  // Context
  const { handleCurrentDropdownResponse } = useSetupContext();

  // Functions
    const handleInput = (value) => {
      console.log('Responses', Responses);
        let Response = Responses ? structuredClone(Responses) : {
            Response: ''
        };

        Response.Response = value;
        handleCurrentDropdownResponse(Index, Response);
    }

    return (
        <div>
            <div className="d-flex w-100 flex-wrap gap-1 input-div">
                <input
                    type="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={Responses?.Response || ""}
                    onChange={(e) => handleInput(e.target.value)}
                    placeholder={"Number"}
                    className="Q8input-response"
                    required
                />
            </div>
        </div>
    )
}

export default NumberInputTool