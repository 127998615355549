// Libraries
import React, { useEffect, useState } from 'react'

// Context
import { useSetupContext } from '../../Context/index';

const MultiSelectionTool = () => {
  // Context
  const { currentQuestion, currentResponses, handleCurrentResponse, PushQuestions, PopQuestions } = useSetupContext();

  // Custom States
  const [Options, setOptions] = useState([]);

  // Functions
  const handleSelection = (option) => {
    let Response = Array.isArray(currentResponses) ? currentResponses : [];
    const isSelected = Array.isArray(Response) && Response.find((res) => res.Response === option);

    let updatedResponse;
    if(isSelected) {
      updatedResponse = Response.filter((res) => res.Response !== option);
      const Option = currentQuestion.options.find((q) => q.choice === option)
      console.log("Option", Option);

      if(Array.isArray(Option.followupQuestions) && Option.followupQuestions.length > 0){
        const QuestionsPop = Option.followupQuestions.map((question) => ({
          ...question,
          Keyelement: option,
          ...(currentQuestion.Keyelement && { FactorKey: currentQuestion.Keyelement })
        }));

        PopQuestions(QuestionsPop);
      }

    } else {
      const newOption = currentQuestion.options.find((p) => p.choice === option)
      
      if(Array.isArray(newOption?.followupQuestions) && newOption?.followupQuestions.length > 0){
        const QuestionsPush = newOption.followupQuestions.map((question) => ({
          ...question,
          Keyelement: option,
          ...(currentQuestion.Keyelement && { FactorKey: currentQuestion.Keyelement })
        }));

        PushQuestions(QuestionsPush);
      };
      updatedResponse = [...Response, { Response: option }];
    }

    handleCurrentResponse(updatedResponse);
  };

  // Effects
  useEffect(() => {
    setOptions(currentQuestion?.options);
  }, [currentQuestion]);

  return (
    <div className="d-flex w-100 flex-wrap gap-1">
      <div className="d-flex w-100 flex-wrap gap-1">
        {Options.map((option, index) => (
          <div className="mb-2" key={index}>
            <button
              className={`btn btn-choice ${
                Array.isArray(currentResponses) && currentResponses.find((res) => res.Response === option.choice) ? "selected" : ""
              }`}
              onClick={() => handleSelection(option.choice)}
            >
              {option.choice}
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

export default MultiSelectionTool