// Libraries
import React from 'react';
import { Link } from 'react-router-dom';

// Styles
import logo from '../../../../../assets/images/blue-logo.png';
import '../../../../../assets/styles/components/onBoarding.scss'

const Header = () => {
  return (
    <nav class="navbar navbar-light">
        <div class="container-fluid ">
          <Link to='/Home' class="navbar-brand m-auto text-center text-header" href="#">
            <img src={logo} width="100" height="35" className="d-inline-block align-top" alt="aallie logo" />
            <br /> Create Financial Plan
          </Link>
        </div>
    </nav>
  )
}

export default Header