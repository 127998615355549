// Libraries
import React from 'react';
import { Outlet } from 'react-router-dom';

// Components
import Header from "./Components/Header";

const Landing = () => {
  return (
    <>
      <Header />
      <div>
        <Outlet />
      </div>
    </>
  )
}

export default Landing