// Libraries
import React, { createContext, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

// Redux
import { AddQuestions, RemoveQuestions, AddDropdownQuestions, RemoveDropdownQuestions, setQuestions, setUserResponses, setCurrentResponse, setCurrentDropdownResponses } from '../../../../../../../Store/Features/QuestionnerSlice';

// Context
const SetupContext = createContext();
export const useSetupContext = () => useContext(SetupContext);

// Provider
export const SetupProvider = ({ children }) => {
    // States State
    const [ResponseChange, setResponseChange] = useState(false);

    // Selectors
    const Questions = useSelector(state => state.questionner.Questions);
    const ConditionalQuestions = useSelector(state => state.questionner.ConditionalQuestions);
    const UserResponses = useSelector(state => state.questionner.UserResponses);

    const currentQuestion = useSelector(state => state.questionner.currentQuestion);
    const currentResponse = useSelector(state => state.questionner.currentResponse);
    const DropdownQuestions = useSelector(state => state.questionner.currentDropdownQuestions);
    const DropdownResponses = useSelector(state => state.questionner.currentDropdownResponses);

    const currentResponses = currentResponse?.Responses || [];
    const currentDropdownResponses = DropdownResponses || [];

    // Dispatch
    const dispatch = useDispatch();

    const handlePopUp = (questions) => {
        if(!questions || questions.length === 0) return;

        let Questions_Stack = [];
        let PopBasket = [];

        Questions_Stack.push(...questions);
        while(Questions_Stack.length > 0) {
            const currentquestion = Questions_Stack.pop();
            PopBasket.push(currentquestion);

            const response = UserResponses.find(response => response?.QuestionID === currentquestion.id);
            if(response){
                switch (currentquestion.responsetype) {
                    case 'One-User-Selection':
                    case 'One-combobox-Selection':
                        let selectedOption = currentquestion.options.find(option => option.choice === response?.Responses?.Response);
                        if (selectedOption) {
                            switch (selectedOption.followuptype) {
                            case 'Non-repeated':
                                let followupQuestions = selectedOption?.followupQuestions || [];
                                followupQuestions.forEach(followupQuestion => {
                                    const followupQuestionById = Questions.find(q => q.id === followupQuestion.id);
                                    if (followupQuestionById) {
                                        Questions_Stack.push(followupQuestionById);
                                    }
                                });
                                break;
            
                            default:
                                break;
                            }
                        }
                        break;
        
                    case 'Multi-User-Selection':
                        switch (currentquestion.optionstype) {
                            case 'Static':
                                response.Responses.forEach(responseItem => {
                                    let selectedOption = currentquestion.options.find(option => option.choice === responseItem.Response);
                                    let followupQuestions = selectedOption?.followupQuestions || [];
                                    followupQuestions.forEach(followupQuestion => {
                                        const followupQuestionById = Questions.find(q => q.id === followupQuestion.id && q?.Keyelement === responseItem.Response && q?.FactorKey === currentquestion?.Keyelement);
                                        if (followupQuestionById) {
                                            Questions_Stack.push(followupQuestionById);
                                        }
                                    });
                                });
                                break;
            
                            case 'AI Generated':
                            case 'Dynamic':
                                let followupQuestions = currentquestion.followupQuestions || [];
                                switch (currentquestion.followuptype) {
                                    case 'Non-repeated':
                                        followupQuestions.forEach(followupQuestion => {
                                            const followupQuestionById = Questions.find(q => q.id === followupQuestion.id);
                                            if (followupQuestionById) {
                                                Questions_Stack.push(followupQuestionById);
                                            }
                                        });
                                        break;
                
                                    case 'Repeated':
                                        if (Array.isArray(response?.Responses) && response?.Responses.length > 0){
                                            response.Responses.forEach(responseItem => {
                                                followupQuestions.forEach(followupQuestion => {
                                                    const followupQuestionById = Questions.find(q => q.id === followupQuestion.id && q?.Keyelement === responseItem.Response && q?.FactorKey === currentquestion.Keyelement);
                                                    if (followupQuestionById) {
                                                        Questions_Stack.push(followupQuestionById);
                                                    }
                                                });
                                            });
                                        }
                                        break;
                
                                    default:
                                        break;
                                }
                                break;
                            
                            case 'Variable':
                                switch (currentquestion.followuptype) {
                                    case 'Repeated':
                                        if (Array.isArray(response?.Responses) && response?.Responses.length > 0){
                                            response?.Responses?.forEach(responseItem => {
                                                if(responseItem.state === 'active'){
                                                    let followupQuestions = currentquestion.followupQuestions || [];
                                                    followupQuestions.forEach(followupQuestion => {
                                                        const followupQuestionById = Questions.find(q => q.id === followupQuestion.id && q?.Keyelement === responseItem.choice && q?.FactorKey === currentquestion.Keyelement);
                                                        if (followupQuestionById) {
                                                            Questions_Stack.push(followupQuestionById);
                                                        }
                                                    });
                                                }
                                            });
                                        }
                                        break;

                                    default:
                                        break;
                                };
                            default:
                                break;
                        }
                        break;

                    default:
                      break;
                }
            }
        };

        return PopBasket;
    }

    const PopQuestions = (questions) => {
        const PoppedQuestions = handlePopUp(questions);
        dispatch(RemoveQuestions({ questions: PoppedQuestions }));
    };

    const PushQuestions = (questions) => {
        dispatch(AddQuestions({ questions: questions }));
    };

    const handlePopDropdown = (questions) => {
        if(!questions || questions.length === 0) return;

        let Questions_Stack = [];
        let PopBasket = [];

        Questions_Stack.push(...questions);
        while(Questions_Stack.length > 0) {
            let currentquestion = Questions_Stack.pop();
            PopBasket.push(currentquestion);

            switch(currentquestion?.responsetype){
                case "One-combobox-Selection":
                case "One-User-Selection":
                    const response = DropdownResponses.find((response) => response.QuestionID === currentquestion.id);
                    const selectedOption = currentquestion?.options.find((option) => option.choice === response?.Responses?.Response);

                    if(selectedOption){
                        PopBasket.push(...selectedOption?.listQuestions || []);
                    }
                    break;

                default:
                    break;
            }
        }

        return PopBasket;
    };

    const PopDropdown = (questions) => {
        const PoppedDropdown = handlePopDropdown(questions);
        dispatch(RemoveDropdownQuestions({ questions: PoppedDropdown }));
    };

    const PushDropdown = (questions) => {
        dispatch(AddDropdownQuestions({ questions: questions }));
    };

    const handleCurrentResponse = (response) => {
        let updatedResponse;

        if (!currentResponse) {
            updatedResponse = { QuestionID: currentQuestion.id, Responses: response };

            if (currentQuestion?.Keyelement) {
                updatedResponse.Keyelement = currentQuestion.Keyelement;
            }
        } else {
            updatedResponse = { ...currentResponse, Responses: response };
        }

        dispatch(setCurrentResponse(updatedResponse));
        setResponseChange(true);
    };

    const handleCurrentDropdownResponse = (Index, response) => {
        let updatedResponse = [...DropdownResponses];
    
        if (!updatedResponse[Index]) {
            updatedResponse[Index] = { QuestionID: DropdownQuestions[Index].id, Responses: response };
        } else {
            updatedResponse[Index] = { 
                ...updatedResponse[Index], 
                Responses: response
            };
        }
        
        dispatch(setCurrentDropdownResponses(updatedResponse));
        setResponseChange(true);
    };

    const GetasyncOptions = async (question) => {
        console.log(question);
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/fetch-${question.Step}-response`,
            { FinanicialplanId: 1, QuestionId: question.QuestionID },
            { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('Token')}` }}
        );

        return response.data.QuestionResponse;
    };

    const GetDynamicOptions = async () => {
        let Options = [];

        await Promise.all(currentQuestion.DynamicOptions.map(async (q) => {
            let options = [];

            if(q?.Step){
                const Responses = await GetasyncOptions(q);

                Responses.forEach((response) => {
                    options.push(response.Response);
                });

            } else {
                const question = Questions.find((p) => p.id === q.QuestionID);
                const Responses = UserResponses.find((response) => response?.QuestionID === q.QuestionID && response?.Keyelement === question?.Keyelement);

                if (!Responses) return;

                switch (question.optionstype) {
                    case 'AI Generated':
                    case 'Dynamic':
                        Responses.Responses.forEach((response) => {
                            options.push(response.Response);
                        });
                        break;

                    case 'Variable':
                        Responses.Responses.forEach((response) => {
                            if(response.state === 'active'){
                                options.push(response.choice);
                            }
                        });
                        break;

                    default:
                        break;
                }
            }

            Options.push(...options);
        }));

        return Options;
    }

    const handleConditionlQuestions = () => {
        let QuestionsAdded = [];
        let QuestionsRemoved = [];

        ConditionalQuestions.forEach((question) => {
            const requiredQuestions = question.Questions_required;

            const condition = requiredQuestions.some((requiredQuestion) => {
                const response = UserResponses.find((response) => response.QuestionID === requiredQuestion.QuestionID);
                return Array.isArray(response?.Responses) && response?.Responses.length > 0;
            });

            if(condition){
                QuestionsAdded.push(question);
            } else {
                QuestionsRemoved.push(question);
            }
        });

        let Questionner = [...Questions];
        let Responses = [...UserResponses];

        if(QuestionsAdded.length > 0){
            QuestionsAdded.forEach((question) => {
                const existed = Questionner.find((q) => q.id === question.id);
                if(!existed){
                    Questionner.push(question);
                }
            });
        }

        if(QuestionsRemoved.length > 0){
            const PoppedQuestions = handlePopUp(QuestionsRemoved);
            Questionner = Questionner.filter((q) => !PoppedQuestions.some((question) => question.id === q.id));
            Responses = Responses.filter((r) => !PoppedQuestions.some((question) => question.id === r.QuestionID));
        }

        if (JSON.stringify(Questions) !== JSON.stringify(Questionner)) {
            dispatch(setQuestions(Questionner));
        }

        if (JSON.stringify(UserResponses) !== JSON.stringify(Responses)) {
            dispatch(setUserResponses(Responses));
        }

        setResponseChange(false);
    };

    return (
        <SetupContext.Provider value={{
            ResponseChange,
            setResponseChange,
            Questions,
            UserResponses,
            currentQuestion,
            currentResponses,
            DropdownQuestions,
            currentDropdownResponses,
            handleConditionlQuestions,
            handleCurrentResponse,
            PushQuestions,
            PopQuestions,
            handleCurrentDropdownResponse,
            PushDropdown,
            PopDropdown,
            GetDynamicOptions
        }}>
            {children}
        </SetupContext.Provider>
    )
}