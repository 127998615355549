// Libraries
import React from 'react'

// Tools
import VariableInput from '../Common/VariableInput';

// Context
import { useSetupContext } from '../../Context/index';


const RegularInputTool = ({Index, Question, Responses}) => {
    // Context
    const { handleCurrentDropdownResponse } = useSetupContext();

    // Functions
    const handleInput = (value) => {
        let Response = Responses ? structuredClone(Responses) : {
            Response: { Value: '' },
            VariableInputFlag: false
        };

        Response.Response.Value = value;
        handleCurrentDropdownResponse(Index, Response);
    }

    const handleVariable = () => {
        let Response = Responses ? structuredClone(Responses) : {
            Response: { Value: '' },
            VariableInputFlag: false
        };

        Response.VariableInputFlag = !Response.VariableInputFlag;
        if (Response.VariableInputFlag) {
            Response.Response = [];
        } else {
            Response.Response = { Value: '' };
        }

        handleCurrentDropdownResponse(Index, Response);
    };

    const handleInputVariable = (value) => {
        let Response = {...Responses};
        if(!Response){
            Response = {
                Response: [],
                VariableInputFlag: true
            }
        }

        Response.Response = value;
        handleCurrentDropdownResponse(Index, Response);
    };

    return (
        <div className="d-flex align-items-center gap-2">
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {!Responses?.VariableInputFlag && (
                    <>
                        <div className="d-flex align-items-center input-div">
                            <span className="Q8-dollar-icon">$</span>
                            <div style={{ flex: 1, padding: '0' }}>
                                <input type="number" inputMode="numeric" pattern="[0-9]*" className=" w-32 px-3 py-2 border border-gray-300  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" placeholder='e.g 10' style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0', borderTopRightRadius: '0', borderBottomRightRadius: '0', width: '100%' }} value={Responses?.Response?.Value || ""} onChange={(e) => handleInput(e.target.value)} />
                            </div>
                        </div>
                        <div>
                            <span className="Q8-dollar-icon-sub">Every Subscription</span>
                        </div>
                    </>
                )}
                {Question.VariableEnable === "true" && (
                    <div className="d-flex justify-content-center gap-2" style={{ display: "flex", flexDirection: "column", marginLeft: '10px' }}>
                        <label className="toggle-switch">
            <input
              type="checkbox"
              checked={Responses?.VariableInputFlag || false}
              onChange={() =>  handleVariable()}
          
            />
            <span className="toggle-slider">
              <span className="toggle-knob" />
            </span>
            <span className="toggle-label">Variable Input</span>
          </label>
                        {/* <div style={{ display: "flex", flexDirection: "row" }}>
                            <div className="form-check form-switch p-0 mt-3">
                                <input
                                    id="flexSwitchCheckDefault"
                                    type="checkbox"
                                    className='form-check-input ms-0 me-1'
                                    checked={Responses?.VariableInputFlag || false}
                                    onChange={() => handleVariable()}
                                    style={{ marginLeft: "10px" }}
                                />
                                <label className='form-check-label' htmlFor="flexSwitchCheckDefault">Variable Input</label>
                            </div>
                        </div> */}
                        {Responses?.VariableInputFlag && (
                            <VariableInput
                                Data={Responses?.Response}
                                onDataChange={(value) => handleInputVariable(value)}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default RegularInputTool