// Libraries
import React from 'react';
import { Link } from 'react-router-dom';

// Styles
import { Divider } from 'antd';
import '../../../assets/styles/pages/privacyPolicy.scss';

// Components
import TermsSection from '../Components/TermsSection';

const TermsPage = () => {
  return (
    <div className="privacySection">
      <div className="container">
        <h1>TERMS AND CONDITIONS</h1>
        <Divider/>
        <div className="row mt-3">
          <div className="col-2">
            <Link to='/Home/privacy-and-policy'>
              <button className='btn btn-terms'>PRIVACY POLICY</button>
            </Link>
          </div>
          <TermsSection />
        </div>
      </div>
    </div>
  )
}

export default TermsPage