
import { useState, useEffect } from "react";
import { BarChart2, Users, DollarSign, Briefcase, PieChart, ChevronRight, XCircle } from 'lucide-react';
import reportsData from "../Common/Reports.json";
import "./../../../../../../../assets/styles/pages/financial-reports.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { message, Spin } from "antd";

export default function CombinedFinancialReports() {
  const [reports, setReports] = useState([]);
  const [hoveredReport, setHoveredReport] = useState(null);
  const navigate = useNavigate();
  const { stepStatus } = useSelector((state) => state.plan);

  useEffect(() => {
    setReports(reportsData.reports);
  }, []);

  const formatTitleForUrl = (title) => {
    return title.toLowerCase().replace(/\s+/g, "-");
  };

  const handleNavigate = (title) => {
    const formattedTitle = formatTitleForUrl(title);
    navigate(`/User/Reports/${formattedTitle}`);
  };

  return (
    <div className="financial-reports">
      <div className="container">
        <h1 className="title">Finance Reports</h1>
        <p className="description">
        Our comprehensive finance reports provide key insights into the financial health and performance of your business. These reports include detailed information on Profit & Loss, Cash Flow, and the Balance Sheet, helping you make informed decisions to drive growth and stability.
        </p>

        <div className="reports-container">
          <div className="reports-list2">
            {reports.map((report, index) => {
              return (
                <div
                  key={report.id}
                  className={`report ${
                    hoveredReport === index &&
                    stepStatus[report.title] === "Completed"
                      ? "hovered"
                      : ""
                  }`}
                  onMouseEnter={() => setHoveredReport(index)}
                  onMouseLeave={() => setHoveredReport(null)}
                >
                  <div
                
                    className={` ${
                      stepStatus[report.title] === "Completed"
                        ? "report-number"
                        : "report-numberNot"
                    } `}
                    // onClick={() => {
                    //   if (stepStatus[report.title] === "Completed") {
                    //     handleNavigate(report.title);
                    //   }
                    // }}
                  >
                    {/* <IconComponent className="icon" /> */}
                    <span>{report.id}</span>
                  </div>

                  <div 
                    className={` ${
                      stepStatus[report.title] === "Completed"
                        ? "report-content"
                        : "report-contentNot"
                    } `}
                    onClick={() => {
                      if (stepStatus[report.title] === "Completed") {
                        handleNavigate(report.title);
                      }
                    }}
                  >
                    <h2 className="report-title">{report.title}</h2>
                    <h3 className="report-subtitle">{report.subtitle}</h3>
                    <p className="report-description">{report.description}</p>
                    <div
                      className={` ${
                        stepStatus[report.title] === "Completed"
                          ? "chevron"
                          : "chevronNot"
                      } `}
                      onClick={() => {
                        if (stepStatus[report.title] === "Completed") {
                          handleNavigate(report.title);
                        }
                      }}
                    >
                      {stepStatus[report.title] === "Completed" ? (
                        <ChevronRight />
                      ) : (
                        <XCircle />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
