// Libraries
import React, { useEffect, useRef, useState } from 'react'

// Context
import { useSetupContext } from '../../Context/index';

const ComboboxSelectionTool = ({ Index, Question, Responses }) => {
  // Context
  const { handleCurrentDropdownResponse, PushDropdown, PopDropdown} = useSetupContext();

  // Hooks
  const dropdownRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  const handleSelection = (value) => {
    setIsOpen(false)
  
    let Response = Responses;

    const isSelected = Response?.Response === value;
    const previousOption = Question.options.find((q) => q.choice === Response?.Response)

    if(previousOption && previousOption?.listQuestions) {
      PopDropdown(previousOption.listQuestions);
    }

    let updatedResponse;
    if(isSelected) {
      updatedResponse = { Response: "" }
    } else {
      updatedResponse = { Response: value }

      const newOption = Question.options.find((p) => p.choice === value)
      if(newOption && newOption?.listQuestions) {
        PushDropdown(newOption.listQuestions);
      }
    }

    handleCurrentDropdownResponse(Index, updatedResponse);
  }

  const handleToggle = () => setIsOpen(!isOpen)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className="d-flex w-100 flex-wrap gap-1">
      <div className="select-wrapper" ref={dropdownRef}>
        <div className="custom-select" onClick={handleToggle}>
          <span className="select-text">
            {Responses && Responses?.Response ? Responses?.Response  : "Select an Option"}
          </span>
          <svg 
            className={`select-arrow ${isOpen ? 'open' : ''}`}
            width="15" 
            height="10" 
            viewBox="0 0 15 10" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <path 
              d="M2.72727 0L0 2.72727L7.27273 10L14.5455 2.72727L11.8182 0L7.27273 4.54545L2.72727 0Z" 
              fill="#EE6C4D" 
              fillOpacity="0.5"
            />
          </svg>
        </div>
        {isOpen && 
          <div className="select-dropdown">
            {Question?.options.map((option, index) => (
              <div 
                key={index} 
                className="select-option" 
                onClick={() => handleSelection(option.choice)}
              >
                {option.choice}
              </div>
            ))}
          </div>
        }
      </div>

{/* <div className="select-wrapper" >
      <select 
        className="custom-select"
       
        value={Responses?.Response || ""}
        onChange={(e) => handleSelection(e.target.value)}
        required
      >
           <option value="" disabled>
          Select an option
        </option>
        {Question?.options.map((option, index) => (
          <option key={index} value={option.choice}>
            {option.choice}
          </option>
        ))}
      </select>
      <svg 
        className="select-arrow"
        width="15" 
        height="10" 
        viewBox="0 0 15 10" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
      >
        <path 
          d="M2.72727 0L0 2.72727L7.27273 10L14.5455 2.72727L11.8182 0L7.27273 4.54545L2.72727 0Z" 
          fill="#EE6C4D" 
          fillOpacity="0.5"
        />
      </svg>
    </div> */}
    </div>
  )
}

export default ComboboxSelectionTool