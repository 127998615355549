import React from 'react';
import { Form, Input, Button, message } from 'antd';
import Lottie from 'lottie-react';
import loginLottie from '../../../assets/lottie/Login.json';
import '../../../assets/styles/pages/login.scss';
import { LoadingOutlined } from '@ant-design/icons';

const ResetPage = () => {
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/password/email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Email: values.email }),
      });

      if (response.ok) {
        message.success('Password reset email sent!');
      } else {
        message.error('Failed to send password reset email.');
      }
    } catch (error) {
      message.error('An error occurred while sending the password reset email.');
    }
  };

  const [loading, setLoading] = React.useState(false);

  return (
    <div className="login-section">
      <div className="container login-container">
        <div className="row">
          <div className="col-12 col-lg-6 Login-design d-flex justify-content-center align-items-center">
            <div className="login-form-wrapper">
              <h2>Please enter your email address to receive a link for password reset.</h2>
              <Form
                form={form}
                onFinish={async (values) => {
                  setLoading(true);
                  await handleSubmit(values);
                  setLoading(false);
                }}
                layout="vertical"
                className="mt-3 form-desin-login"
              >
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: 'Please input your email!' },
                    { type: 'email', message: 'Please enter a valid email!' },
                  ]}
                >
                  <Input placeholder="Enter your email" />
                </Form.Item>
                <Form.Item>
                  <div className="col-12 mt-3">
                    {loading ? (
                      <div className='d-flex justify-content-center align-items-center'>
                        <LoadingOutlined />
                      </div>
                    ) : (
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="btn btn-submit-login w-100"
                      >
                        Send Reset Email
                      </Button>
                    )}
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
          <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
            <Lottie className="login-logo" animationData={loginLottie} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPage;
