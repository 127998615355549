// Libraries
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import OTPInput from 'react-otp-input';
import axios from 'axios';

// Assets
import registerLottie from '../../../assets/lottie/Signup.json';

// Styles
import '../../../assets/styles/pages/register.scss';
import { message, Spin } from 'antd';

const VerifyPage = () => {
  const [OTP, setOTP] = useState(null);
  const [loading, setLoading] = useState(false); 
  const handleChange = (otp) => {
    setOTP(otp);
  };

  const [timer, setTimer] = useState(30);
  const [canResend, setCanResend] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoading(true); // Show spinner
    try {
      const apiURL = `${process.env.REACT_APP_API_BASE_URL}/auth/verify-otp`;
      const response = await axios.post(apiURL, 
        { OTP: OTP }, 
        { 
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('Token')}`
          } 
        }
      );
      
      const data = response.data;
      if(data.status === 'success') {
        message.success('OTP verified successfully');
        navigate('/User/Onboarding');
      } else {
        message.error('Invalid OTP');
      }
    } catch (error) {
      console.log(error);
    }finally {
      setLoading(false); 
    }
  };

  const handleresendOTP = async () => {
    setTimer(30);

    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/send-otp`, {},
        { 
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('Token')}`
          } 
        }
      );

    } catch (error) {
      console.log(error);
    } finally {
      setCanResend(false);
    }
  };

  useEffect(() => {
    const send_OTP = async () => {
      try{
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/send-otp`, {},
          { 
            headers: { 
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('Token')}`
            } 
          }
        );
      } catch (error) {
        console.log(error);
      }
    };

    send_OTP();
  }, []);

  useEffect(() => {
    if (timer > 0) {
      const countdown = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(countdown);
    } else {
      setCanResend(true);
    }
  }, [timer]);
    
  return (
    <div className="register-section text-center d-flex align-items-center justify-content-center" style={{ minHeight: '80vh' }}>
      <div className='container'>
        <div className="row justify-content-center align-items-center">
          <div className="col-5">
            <Lottie className="register-logo" animationData={registerLottie} />
          </div>
          <div className="col-7">
            <h2>Verify Your Account</h2>
            <p style={{ marginTop: "10px" }}>Enter the OTP sent to your email address</p>
            <div className='otp-div'>
              <OTPInput
                value={OTP}
                onChange={handleChange}
                numInputs={6}
                separator={<span>-</span>}
                inputStyle="otp-input"
                containerStyle="otp-container"
                renderInput={(props) => <input {...props} />}
              />
            </div>
            <button className="btn btn-next-register mt-3" onClick={() => handleSubmit()}>
              {loading ? <Spin size="small" /> : 'Submit OTP'}
            </button>   
            <div className='mt-4'>
              {canResend ? (
                <button 
                  className="resend-otp-link" 
                  onClick={handleresendOTP}
                >
                  Resend OTP
                </button>
              ) : (
                <p>Resend OTP in {timer}s</p>
              )}
            </div>     
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyPage