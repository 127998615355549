// Libraries
import React from 'react'

// Context
import { useSetupContext } from '../../Context/index';

const PercentageInputTool = () => {
    // Context
    const { currentResponses, handleCurrentResponse } = useSetupContext();

    // Functions
    const handleInput = (value) => {
        let updatedResponse = { Response: value }
        handleCurrentResponse(updatedResponse);
    };

    return (
        <div className="d-flex w-100 flex-wrap gap-1 input-div">
            <span className="Q8-dollar-icon">%</span>
            <input
                type="number"
                value={currentResponses?.Response || ""}
                onChange={(e) => handleInput(e.target.value)}
                placeholder={"Percentage"}
                className="Q8input-response"
                required
            />
        </div>
    )
}

export default PercentageInputTool