// Libraries
import React, { useEffect, useState } from 'react';

// Context
import { useOnboardingContext } from '../Context/index';

// CSS Components
import { Form, Input } from 'antd';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

// Styles
import '../../../../../assets/styles/components/onBoarding.scss';

const StepTwo = () => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { formData, handleFormData } = useOnboardingContext();

  useEffect(() => {
    form.setFieldsValue({
      ONBOARDING_BUSSINES_DES: formData.ONBOARDING_BUSSINES_DES,
    });
  }, [form, formData.ONBOARDING_BUSSINES_DES]);

  const handleValuesChange = (changedValues) => {
    const key = Object.keys(changedValues)[0];
    const value = Object.values(changedValues)[0];
    handleFormData(key, value);
  };
  const [isFocused, setIsFocused] = useState(false)
  return (
    <div className="scrollable-buttons-container">
      <div className="text-start q1-onboarding">
        <h1 className="text-center">Where is the business based?</h1>
        <p>
          If the business will operate internationally, select the country where the head office will be situated.
        </p>
        <Form.Item>
          <div style={{ display: 'flex', gap: '10px' }}>
          <div className="country-dropdown-container">
            <CountryDropdown
              className="selection"
              value={formData.ONBOARDING_SELECTED_COUNTRY}
              onChange={(value) => handleFormData('ONBOARDING_SELECTED_COUNTRY', value)}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              classes={`selection ${isFocused ? "focused" : ""}`}
            />
                 <svg
          className={`dropdown-icon ${isFocused ? "focused" : ""}`}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5 7.5L10 12.5L15 7.5" stroke="#98C1D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
            </div>
            <div className="country-dropdown-container">
            <RegionDropdown
              className="selection"
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              classes={`selection ${isFocused ? "focused" : ""}`}
              country={formData.ONBOARDING_SELECTED_COUNTRY}
              value={formData.ONBOARDING_SELECTED_STATE}
              onChange={(value) => handleFormData('ONBOARDING_SELECTED_STATE', value)}
            />
                     <svg
                       className={`dropdown-icon ${isFocused ? "focused" : ""}`}
    
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5 7.5L10 12.5L15 7.5" stroke="#98C1D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
            </div>
          </div>
        </Form.Item>
      </div>

      <div className="text-start q1-onboarding mt-1">
        <h1 className="text-center">What does the business do?</h1>
        <p>
          This will not appear in your plan; it just helps us understand the business. Below are a few examples:
          <ul className="custom-bullets">
            <li>We are a supplier of specialist glass for use in commercial buildings.</li>
            <li>We are a marketing agency specializing in TikTok and YouTube.</li>
          </ul>
        </p>
        <Form
          form={form}
          onValuesChange={handleValuesChange}
          initialValues={{
            ONBOARDING_BUSSINES_DES: formData.ONBOARDING_BUSSINES_DES,
          }}
        >
          <Form.Item
            name="ONBOARDING_BUSSINES_DES"
            rules={[{ required: true, message: 'Please provide a business description' }]}
          >
            <TextArea
              placeholder="e.g. We sell electronics in-store and online, we specialize in high-end laptops."
              autoSize={{ minRows: 2, maxRows: 4 }}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default StepTwo;