// Libraries
import React from 'react'

// Context
import { useSetupContext } from '../../Context/index';

const OneSelectionTool = ({ Index, Question, Responses }) => {
  // Context
  const { handleCurrentDropdownResponse, PushDropdown, PopDropdown} = useSetupContext();

  // Handle Selection
  const handleSelection = (value) => {
    let Response = Responses;

    const isSelected = Response?.Response === value;
    const previousOption = Question.options.find((q) => q.choice === Response?.Response)

    if(previousOption && previousOption?.listQuestions) {
      PopDropdown(previousOption.listQuestions);
    }

    let updatedResponse;
    if(isSelected) {
      updatedResponse = { Response: "" }
    } else {
      updatedResponse = { Response: value?.choice }

      const newOption = Question.options.find((p) => p.choice === value?.choice)
      if(newOption && newOption?.listQuestions) {
        PushDropdown(newOption.listQuestions);
      }
    }

    handleCurrentDropdownResponse(Index, updatedResponse);
  }

  return (
    <div className="d-flex w-100 flex-wrap gap-1">
      {Question.options.map((option, index) => (
        <div className="mb-2" key={index}>
          <button
            className={`btn btn-choice ${
                Responses?.Response === option.choice ? "selected" : ""
            }`}
            onClick={() => handleSelection(option)}
          >
            {option.choice}
          </button>
        </div>
      ))}
    </div>
  )
}

export default OneSelectionTool