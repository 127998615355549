// Libraries
import React from 'react'

// Tools
import VariableInput from '../Common/VariableInput';

// Context
import { useSetupContext } from '../../Context/index';

const PercentageInputTool = ({Index, Question, Responses}) => {
  // Context
  const { handleCurrentDropdownResponse } = useSetupContext();

  // Functions
    const handleInput = (value) => {
      console.log('Responses', Responses);
        let Response = Responses ? structuredClone(Responses) : {
            Response: '',
            VariableInputFlag: false
        };

        Response.Response = value;
        handleCurrentDropdownResponse(Index, Response);
    }

    const handleVariable = () => {
        let Response = Responses ? structuredClone(Responses) : {
            Response: '',
            VariableInputFlag: false
        };

        Response.VariableInputFlag = !Response.VariableInputFlag;
        if (Response.VariableInputFlag) {
            Response.Response = [];
        } else {
            Response.Response = '';
        }

        handleCurrentDropdownResponse(Index, Response);
    };

    const handleInputVariable = (value) => {
        let Response = {...Responses};
        if(!Response){
            Response = {
                Response: [],
                VariableInputFlag: true
            }
        }

        Response.Response = value;
        handleCurrentDropdownResponse(Index, Response);
    };

    return (
        <div className="d-flex align-items-center gap-2">
            {!Responses?.VariableInputFlag && (
                <div className="d-flex align-items-center input-div">
            <span className="Q8-dollar-icon">%</span>
            <input
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        value={Responses?.Response || ""}
                        onChange={(e) => handleInput(e.target.value)}
                        placeholder={"Percentage"}
                       className=" w-32 px-3 py-2 border border-gray-300  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        required
                        style={{ borderTopLeftRadius: '0.25rem', borderBottomLeftRadius: '0.25rem', borderTopRightRadius: '0.25rem', borderBottomRightRadius: '0.25rem' }}
                    />
                </div>
            )}
            {Question.VariableEnable === "true" && (
                <div className="d-flex justify-content-center gap-2" style={{display: "flex", flexDirection:"column"}}>
                    <label className="toggle-switch">
            <input
              type="checkbox"
              checked={Responses?.VariableInputFlag || false}
              onChange={() =>  handleVariable()}
          
            />
            <span className="toggle-slider">
              <span className="toggle-knob" />
            </span>
            <span className="toggle-label">Variable Input</span>
          </label>
                    {/* <div tyle={{display: "flex", flexDirection: "row"}}>
                        <div className="form-check form-switch p-0 mt-3 ">
                        <input
                            id="flexSwitchCheckDefault"
                            type="checkbox"
                            className='form-check-input ms-0 me-1'
                            checked={Responses?.VariableInputFlag || false}
                            onChange={() => handleVariable()}
                            style={{marginLeft: "10px"}}
                        />
                        <label className='form-check-label' htmlFor="flexSwitchCheckDefault">Variable Input</label>
                        </div>
                    </div> */}
                    {Responses?.VariableInputFlag && (
                        <VariableInput
                            Data={Responses?.Response}
                            onDataChange={(value) => handleInputVariable(value)}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default PercentageInputTool