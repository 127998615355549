// Libraries
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Redux
import { fetchUserDetails } from './Store/Features/authSlice';

const App = () => {
  // Dispatch
  const dispatch = useDispatch();

  // Fetch User Details
  useEffect(() => {
    const fetchDetails = async () => {
      await dispatch(fetchUserDetails());
    };

    fetchDetails();
  }, [dispatch]);

  return (
    <Outlet />
  )
}

export default App