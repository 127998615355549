import { useSelector } from 'react-redux';
import '../../../../../../../src/assets/styles/system/userSettings.scss';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';

const UserSettings = () => {
    const User = useSelector((state) => state.auth.user);

    const onChange = (key) => {
        console.log(key);
    };

    const items = [
        {
            key: '1',
            label: 'Subscription',
            children: (
                <div className='subscription-tab'>
                    <div className='benefits-container mb-3'>
                        <span>Benefits</span>
                        <div className='line-user'></div>
                    </div>

                    {[...Array(4)].map((_, index) => (
                        <div className='d-flex justify-content-between items-design-plans' key={index}>
                            <span>1 Business</span>
                            <span>2 Chapters</span>
                        </div>
                    ))}

                    <div className='d-flex justify-content-center mt-1 mb-1'>
                        <button className='btn btn-upgrade'>Upgrade</button>
                    </div>
                </div>
            ),
        },
        {
            key: '2',
            label: 'Security',
            children: (
                <div className='security-tab'>
                    <div className='d-flex flex-column mb-3'>
                    <label htmlFor='new-password'>Old Password</label>
                    <input type='password' id='new-password' className='form-control mb-4' />
                        <label htmlFor='new-password'>New Password</label>
                        <input type='password' id='new-password' className='form-control mb-4' />
                        <label htmlFor='repeat-password'>Repeat Password</label>
                        <input type='password' id='repeat-password' className='form-control mb-2' />
                    </div>
                    <div className='d-flex justify-content-center mt-1 mb-1'>
                        <button className='btn btn-save'>Save</button>
                    </div>
                </div>
            ),
        },
    ];

    const handleEdit = () => {
        console.log('Edit button clicked');
    };

    const handleDelete = () => {
        console.log('Delete button clicked');
    };

    return (
        <div className="system-main-container layout-content">
            <div className="container d-flex justify-content-center align-items-center">
                <div className="user-settings-wrapper">
                    <span className="user-header-page-questions">
                        Account Settings
                    </span>
                    <div className="user-settings-card p-4">
                        <div className='d-flex justify-content-between align-items-center'>
                            <h2 className='userNameStyle'>
                                {User?.USR_First_Name && User?.USR_Last_Name ? `${User.USR_First_Name} ${User.USR_Last_Name}` : 'No name found'}
                            </h2>
                            <div>
                                <EditOutlined 
                                    style={{ fontSize: '24px', marginRight: '20px', cursor: 'pointer' }} 
                                    onClick={handleEdit} 
                                />
                                <DeleteOutlined 
                                    style={{ fontSize: '24px', cursor: 'pointer' }} 
                                    onClick={handleDelete} 
                                />
                            </div>
                        </div>
                        <div className='user-settings-card-content'>
                            <Tabs defaultActiveKey="1" onChange={onChange} items={items} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserSettings;
