// Libraries
import React from 'react';
import Lottie from 'lottie-react';

// Styles
import '../../../assets/styles/pages/features.scss';
import mobileLottie from '../../../assets/lottie/Mobile.json'
import report from '../../../assets/lottie/report.json'
import analysis from '../../../assets/lottie/analysis.json'
import register from '../../../assets/lottie/register.json'
import cost from '../../../assets/lottie/cost.json'
import data from '../../../assets/lottie/data.json'
import database from '../../../assets/lottie/database.json'
import tools from '../../../assets/lottie/tools.json'
import analysisSenstive from '../../../assets/lottie/analaysisSenstive.json'

const Features = () => {
    return (
        <div className='feature-page-style'>
            <div className="container pb-5">
                <div className="d-flex justify-content-center">
                    <h3>Our Feature</h3>
                </div>
                <div className="row cards-between justify-content-between mt-5">
                    <div className="col-12 col-md-6 col-lg-4 card-design">
                        <div className="row m-3 justify-content-center align-items-center text-center">
                            <div className="col-12 icont-height d-flex justify-content-center">
                                <Lottie className="card-logo" animationData={mobileLottie} />
                            </div>
                            <div className="col-12">
                                <span>Mobile Responsiveness and Accessibility</span>
                                <div className='hz-line mt-2'></div>
                                <p className='pt-2 text-center'>A design that works on various devices, including tablets and smartphones.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 card-design">
                        <div className="row m-3 justify-content-center align-items-center text-center">
                            <div className="col-12 icont-height d-flex justify-content-center">
                                <Lottie className="card-logo" animationData={report} />
                            </div>
                            <div className="col-12">
                                <span>Generation of detailed cost reports.</span>
                                <div className='hz-line mt-2'></div>
                                <p className='pt-2 text-center'>Visualization tools, like charts and graphs, to represent cost structures. Customizable reporting templates.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 card-design">
                        <div className="row m-3 justify-content-center align-items-center text-center">
                            <div className="col-12 icont-height d-flex justify-content-center">
                                <Lottie className="card-logo" animationData={analysisSenstive} />
                            </div>
                            <div className="col-12">
                                <span>Scenario Analysis and What-if Modeling</span>
                                <div className='hz-line mt-2'></div>
                                <p className='pt-2 text-center'>Tools to perform what-if analyses to see how changes in cost inputs affect the final product cost.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row cards-between justify-content-between mt-5">
                    <div className="col-12 col-md-6 col-lg-4 card-design">
                        <div className="row m-3 justify-content-center align-items-center text-center">
                            <div className="col-12 icont-height d-flex justify-content-center">
                                <Lottie className="card-logo" animationData={register} />
                            </div>
                            <div className="col-12">
                                <span>User Registration and Profiles</span>
                                <div className='hz-line mt-2'></div>
                                <p className='pt-2 text-center'>Secure sign-up and login functionality. Ability to create and manage user profiles.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 card-design">
                        <div className="row m-3 justify-content-center align-items-center text-center">
                            <div className="col-12 icont-height d-flex justify-content-center">
                                <Lottie className="card-logo" animationData={cost} />
                            </div>
                            <div className="col-12">
                                <span>Cost Calculation Engine</span>
                                <div className='hz-line mt-2'></div>
                                <p className='pt-2 text-center'>A robust backend algorithm to calculate costs based on user inputs.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 card-design">
                        <div className="row m-3 justify-content-center align-items-center text-center">
                            <div className="col-12 icont-height d-flex justify-content-center">
                                <Lottie className="card-logo" animationData={data} />
                            </div>
                            <div className="col-12">
                                <span>Input Data Management</span>
                                <div className='hz-line mt-2'></div>
                                <p className='pt-2 text-center'>Forms to input raw material costs, labor costs, overheads, and other direct and indirect costs.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-between mt-5">
                    <div className="col-12 col-md-6 col-lg-4 card-design">
                        <div className="row m-3 justify-content-center align-items-center text-center">
                            <div className="col-12 icont-height d-flex justify-content-center">
                                <Lottie className="card-logo" animationData={database} />
                            </div>
                            <div className="col-12">
                                <span>Product Database</span>
                                <div className='hz-line mt-2'></div>
                                <p className='pt-2 text-center'>A design that works on various devices, including tablets and smartphones.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 card-design">
                        <div className="row m-3 justify-content-center align-items-center text-center">
                            <div className="col-12 icont-height d-flex justify-content-center">
                                <Lottie className="card-logo" animationData={tools} />
                            </div>
                            <div className="col-12">
                                <span>Collaboration Tools</span>
                                <div className='hz-line mt-2'></div>
                                <p className='pt-2 text-center'>A design that works on various devices, including tablets and smartphones.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 card-design">
                        <div className="row m-3 justify-content-center align-items-center text-center">
                            <div className="col-12 icont-height d-flex justify-content-center">
                                <Lottie className="card-logo" animationData={analysis} />
                            </div>
                            <div className="col-12">
                                <span>Sensitivity Analysis</span>
                                <div className='hz-line mt-2'></div>
                                <p className='pt-2 text-center'>Perform sensitivity analysis by changing key variables and assessing their impact on costs.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Features;
