// Libraries
import React, { useEffect } from 'react'

// Components
import "react-datepicker/dist/react-datepicker.css";
import Dropdown from "./DropDown";

// Context
import { useSetupContext } from '../Context/index';

// Tools
import OneSelectionTool from '../Tools/Main/OneSelectionTool';
import AISelectionTool from '../Tools/Main/AISelectionTool';
import VariableSelectionTool from '../Tools/Main/VariableSelectionTool';
import DynamicSelectionTool from '../Tools/Main/DynamicSelectionTool';
import MultiSelectionTool from '../Tools/Main/MultiSelectionTool';
import ComboboxSelectionTool from '../Tools/Main/ComboboxSelectionTool';
import PercentageInputTool from '../Tools/Main/PercentageInputTool';
import AssetsInputTool from '../Tools/Main/AssetsInputTool';
import CostInputTool from '../Tools/Main/CostInputTool';
import DateInputTool from '../Tools/Main/DateInputTool';

// Styles
import '../../../../../../../assets/styles/system/cards.scss';

const Card = () => {
    // Context
    const { currentQuestion, DropdownQuestions, PushDropdown } = useSetupContext();

    // Effect
    useEffect(() => {
        const handleListQuestions = () => {
            if(Array.isArray(currentQuestion?.listQuestions) && currentQuestion?.listQuestions?.length > 0) {
                currentQuestion?.listQuestions?.forEach((question) => {
                    console.log('Condition:', !DropdownQuestions?.find((dropdownQuestion) => dropdownQuestion.id === question.id));
                    if (!DropdownQuestions?.find((dropdownQuestion) => dropdownQuestion.id === question.id)) {
                        PushDropdown([question]);
                    }
                });
            }
        };

        handleListQuestions();
    }, [currentQuestion]);
    
    return (
        <div className="card-container" >
            <div className="card-questioner">
                <h1>{currentQuestion?.span}</h1>
                <h2 className="mb-4">{currentQuestion?.Keyelement}</h2>
                <div className="response-area">
                    <span>{currentQuestion?.title?.replace("[Key Element]", currentQuestion?.FactorKey)}</span>
                    <p>{currentQuestion?.description}</p>

                    {currentQuestion?.responsetype === "One-User-Selection" && (
                        <OneSelectionTool />
                    )}
                    {currentQuestion?.responsetype === "Multi-User-Selection" && (
                        <>
                            {currentQuestion?.optionstype === "AI Generated" ? (
                                <AISelectionTool />
                            ) : currentQuestion?.optionstype === "Variable" ? (
                                <VariableSelectionTool />
                            ) : currentQuestion?.optionstype === "Dynamic" ? (
                                <DynamicSelectionTool />
                            ) : currentQuestion?.optionstype === "Static" ? (
                                <MultiSelectionTool />
                            ) : null}
                        </>
                    )}
                    {currentQuestion?.responsetype === "One-combobox-Selection" && (
                        <ComboboxSelectionTool />
                    )}
                    {currentQuestion?.responsetype === "User-Input-Percentage" && (
                        <PercentageInputTool />
                    )}
                    {currentQuestion?.responsetype === "User-Input-Assets" && (
                        <AssetsInputTool />
                    )}
                    {currentQuestion?.responsetype === "User-Input-Cost" && (
                        <CostInputTool />
                    )}
                    {currentQuestion?.responsetype === "User-Date-Input" && (
                        <DateInputTool />
                    )}

                    {Array.isArray(DropdownQuestions) && DropdownQuestions?.length > 0 && (
                        <div style={{ marginTop: '20px' }}>
                            <Dropdown />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Card