import React from 'react';
import { Form, Input, Button, message } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import loginLottie from '../../../assets/lottie/Login.json'; // adjust the path if necessary
import '../../../assets/styles/pages/login.scss'; // import the login SCSS styling

const PasswordReset = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [form] = Form.useForm();

  const passwordPatterns = [
    {
      regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{12,}$/
    },
    {
      regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{12,}$/
    },
    {
      regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/
    },
    {
      regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,}$/
    }
  ];

  const validatePassword = (password, confirmPassword) => {
    if (password !== confirmPassword) {
        message.error('Password does not match the confirmation');
        return false;
    }
    const matchedPattern = passwordPatterns.find(patternObj => patternObj.regex.test(password));
    if (!matchedPattern) {
        message.error('Your password does not meet the required criteria. Please include uppercase, lowercase, numbers, special characters, and ensure sufficient length.');
        return false;
    }
    return true;
  };

  const handleSubmit = async (values) => {
    if (!validatePassword(values.password, values.confirmPassword)) {
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/password/reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          email: values.email,
          password: values.password,
          password_confirmation: values.confirmPassword,
        }),
      });

      if (response.ok) {
        message.success('Password reset successfully!');
        navigate('/Home/login');
      } else {
        const data = await response.json();
        message.error(data.message || 'Failed to reset password.');
      }
    } catch (error) {
      message.error('An error occurred while resetting the password.');
    }
  };

  return (
    <div className="login-section">
      <div className="container login-container">
        <div className="row">
          {/* Form Section */}
          <div className="col-12 col-lg-6 Login-design d-flex justify-content-center align-items-center">
            <div className="login-form-wrapper">
              <h2>Reset Password</h2>
              <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
                className="mt-3 form-desin-login"
              >
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    { required: true, message: 'Please input your email!' },
                    { type: 'email', message: 'Please enter a valid email!' },
                  ]}
                >
                  <Input placeholder="Enter your email" />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="New Password"
                  rules={[{ required: true, message: 'Please input your new password!' }]}
                >
                  <Input.Password placeholder="Enter your new password" />
                </Form.Item>
                <Form.Item
                  name="confirmPassword"
                  label="Confirm New Password"
                  dependencies={['password']}
                  rules={[
                    { required: true, message: 'Please confirm your new password!' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('The two passwords do not match!'));
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Confirm your new password" />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="btn btn-submit-login w-100">
                    Reset Password
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
          {/* Animation Section */}
          <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
            <Lottie className="login-logo" animationData={loginLottie} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
