// Libraries
import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

// CSS Components
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import {
  Button,
  Layout,
  Drawer,
  theme,
} from 'antd';

// Components
import LargeScreenMenu from './Components/LargeScreenMenu'
import MediumScreenMenu from './Components/MediumScreenMenu'

// Styles
import '../../../../assets/styles/components/layout.scss'
import logo from '../../../../assets/images/white-logo.png';
import smallLogo from '../../../../assets/images/small.png';

const { Header, Sider, Content } = Layout;

const System = () => {
  const [visible, setVisible] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [collapsed, setCollapsed] = useState(false);
  const { token: { borderRadiusLG }, } = theme.useToken();

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('Token');
    navigate('/');
  };

  const toggleSider = () => {
    setCollapsed(!collapsed);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Layout>
        {windowWidth >= 768 ? (
          <Sider trigger={null} collapsible collapsed={collapsed} style={{ position: 'fixed', height: '100%', top: 0 }}>
            <div />
            <div className="p-2 text-white d-flex justify-content-center">
              {collapsed ? <img src={smallLogo} width="50" height="40" className="mt-3" alt="aallie logo" /> : <img src={logo} width="120" height="35" className='mt-3 ' alt="aallie logo" />}
            </div>
            <LargeScreenMenu />
          </Sider>
        ) : (
          <Drawer
            title="Menu"
            placement="left"
            closable={true}
            onClose={onClose}
            open={visible}
            styles={{
              content: {
                backgroundColor: '#3D5A80',
                color: 'white',
              },
              header: {
                backgroundColor: '#3D5A80',
                color: 'white',
              },
            }}
          >
            <div />
            <div className="p-2 text-white">
              {collapsed ? <img src={smallLogo} width="50" height="40" className="mt-3" alt="aallie logo" /> : <img src={logo} width="120" height="35" className='mt-3' alt="aallie logo" />}
            </div>
            <MediumScreenMenu />
          </Drawer>
        )}
        <Layout style={{ marginLeft: windowWidth >= 768 ? (collapsed ? '80px' : '200px') : '0' }}>
          <Header className='header-style' style={{ position: 'fixed', top: 0, width: windowWidth >= 768 ? `calc(100% - ${collapsed ? '80px' : '200px'})` : '100%', zIndex: 1 , marginBottom:'10px'}}>
            <div className="d-flex justify-content-between">
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={windowWidth >= 768 ? toggleSider : showDrawer}
                style={{
                  fontSize: '16px',
                  width: 64,
                  height: 64,
                }}
              />
              <div style={{ width: '100%', textAlign: 'start', color: '#ffff', fontWeight: 'bolder' }}>
                <p>Version {process.env.REACT_APP_VERSION} </p>
              </div>
              <button className='mt-3 m-4 btn btn-logout' onClick={handleLogout}>Logout</button>
            </div>
          </Header>
          <Content
            style={{
              margin: '85px 24px 40px 24px', // Adjusted margin to account for the fixed header and added space
              padding: 24,
              minHeight: 'calc(100vh - 149px)', // Adjusted to ensure content takes full height minus header and margins
              background: '#fff',
              borderRadius: borderRadiusLG,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div className="container layout-contentt" >
              <Outlet />
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  )
}

export default System

