// Libraries
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Inital State
const initialState = {
    Questions: [],
    ConditionalQuestions: [],
    UserResponses: [],
    currentStep: 0,
    currentQuestion: null,
    currentResponse: null,
    currentDropdownQuestions: [],
    currentDropdownResponses: [],
    loading: false,
};

// Thunk Actions
export const fetchQuestionner = createAsyncThunk('questionner/fetchQuestionner', async ({ financialplanId, step }, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/fetch-questionner/${step}`, 
            { FinanicialplanId: financialplanId },
            { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('Token')}` }}
        );

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});
export const SaveResponse = createAsyncThunk('questionner/SaveResponse', async ({ financialplanId, step, Responses }, { rejectWithValue }) => {
    try{
        console.log("Responses", Responses);
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/store-response/${step}`, 
            { FinanicialplanId: financialplanId, UserResponses: Responses },
            { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('Token')}` }}
        );

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});
export const SubmitResponse = createAsyncThunk('questionner/SubmitResponse', async ({ financialplanId, step, Responses }, { rejectWithValue }) => {
    try{
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/submit-response/${step}`,
            { FinanicialplanId: financialplanId, UserResponses: Responses },
            { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('Token')}` }}
        );

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

const questionnerSlice = createSlice({
    name: 'questionner',
    initialState,
    reducers: {
        setStep: (state, action) => {
            state.currentStep = action.payload;
            state.currentQuestion = state.Questions[action.payload];
            state.currentResponse = state.UserResponses[action.payload];
            state.currentDropdownQuestions = state.Questions[action.payload]?.Dropdown || [];
            state.currentDropdownResponses = state.UserResponses[action.payload]?.DropdownResponses || [];
        },
        setQuestions: (state, action) => {
            state.Questions = action.payload;
        },
        AddQuestions: (state, action) => {
            const { questions } = action.payload;

            let Questionner = [...state.Questions];
            let Responses = [...state.UserResponses];
            let Index = state.currentStep;

            if (Array.isArray(questions) && questions.length > 0) {
                if (Index !== -1) {
                    if (state.currentQuestion.followuptype === 'Repeated') {
                        while (Index < Questionner.length && Questionner[Index]?.Keyelement && Questionner[Index]?.FactorKey === state.currentQuestion.Keyelement) {
                            Index++;
                        }
                    }

                    if(Index === Questionner.length - 1) {
                        Questionner.push(...questions);
                    } else {
                        Questionner.splice(Index + 1, 0, ...questions);
                    }

                    if (Index < Responses.length - 1) {
                        Responses.splice(Index + 1, 0, ...questions.map(question => {
                            let NewResponse = { QuestionID: question.id, Responses: "" };

                            if (question?.Keyelement) {
                                NewResponse.Keyelement = question.Keyelement;
                            }

                            return NewResponse;
                        }));
                    }
                }

                state.Questions = Questionner;
                state.UserResponses = Responses;
            }
        },
        RemoveQuestions: (state, action) => {
            const { questions } = action.payload;

            let Questionner = [...state.Questions];
            let Responses = [...state.UserResponses];

            if(Array.isArray(questions) && questions.length > 0) {
                Questionner = Questionner.filter((q) => {
                    return !questions.some(followup => followup.id === q.id && followup?.Keyelement === q?.Keyelement && followup?.FactorKey === q?.FactorKey);
                });
        
                Responses = Responses.filter((r) => {
                    return !questions.some(followup => followup.id === r?.QuestionID && followup?.Keyelement === r?.Keyelement && followup?.FactorKey === r?.FactorKey);
                });
            } 
            
            state.Questions = Questionner;
            state.UserResponses = Responses;
        },
        AddDropdownQuestions: (state, action) => {
            const { questions } = action.payload;

            let Questionner = [...state.currentDropdownQuestions];
            if (Array.isArray(questions) && questions.length > 0) {
                Questionner.push(...questions);
            }

            state.currentDropdownQuestions = Questionner;
            state.Questions[state.currentStep].Dropdown = Questionner;
        },
        RemoveDropdownQuestions: (state, action) => {
            const { questions } = action.payload;

            let Questionner = [...state.currentDropdownQuestions];
            let Responses = [...state.currentDropdownResponses];

            if (Array.isArray(questions) && questions.length > 0) {
                Questionner = Questionner.filter((q) => {
                    console.log(q.id);
                    return !questions.some(followup => followup.id === q.id);
                });
                Responses = Responses.filter((r) => {
                    return !questions.some(followup => followup.id === r?.QuestionID);
                });
            }

            state.currentDropdownQuestions = Questionner;
            state.Questions[state.currentStep].Dropdown = Questionner;
            state.currentDropdownResponses = Responses;
            state.UserResponses[state.currentStep].DropdownResponses = Responses;
        },
        setCurrentResponse: (state, action) => {
            state.currentResponse = action.payload;
            state.UserResponses[state.currentStep] = action.payload;
        },
        setCurrentDropdownResponses: (state, action) => {
            state.currentDropdownResponses = action.payload;
            state.UserResponses[state.currentStep].DropdownResponses = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchQuestionner.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchQuestionner.fulfilled, (state, action) => {
                state.loading = false;
                state.Questions = action.payload.Questions;
                state.ConditionalQuestions = action.payload.Conditional_Questions;
                state.UserResponses = action.payload.UserResponses;
                
                // Current Step 
                state.currentStep = action.payload.Current_Step;
                state.currentQuestion = action.payload.Questions[action.payload.Current_Step];
                state.currentResponse = action.payload.UserResponses[action.payload.Current_Step];
                state.currentDropdownQuestions = action.payload.Questions[action.payload.Current_Step]?.Dropdown || [];
                state.currentDropdownResponses = action.payload.UserResponses[action.payload.Current_Step]?.DropdownResponses || [];
            })
            .addCase(fetchQuestionner.rejected, (state) => {
                state.loading = false;
            })
            .addCase(SubmitResponse.pending, (state) => {
                state.loading = true;
            })
            .addCase(SubmitResponse.fulfilled, (state) => {
                state.loading = false;
                state.currentStep = 0;

                state.Questions = [];
                state.ConditionalQuestions = [];
                state.UserResponses = [];

                state.currentQuestion = null;
                state.currentResponse = null;
                state.currentDropdownQuestions = null;
                state.currentDropdownResponses = null;
            })
            .addCase(SubmitResponse.rejected, (state) => {
                state.loading = false;
            });
    }
});

export const { setStep, setCurrentResponse, AddQuestions, RemoveQuestions, AddDropdownQuestions, RemoveDropdownQuestions, setQuestions, setUserResponses, setCurrentDropdownResponses } = questionnerSlice.actions;
export default questionnerSlice.reducer;