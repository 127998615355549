// Libraries
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Initial State
const initialState = {
    stepStatus: {},
    onboardingData: {},
    loading: false,
    error: null,
};

// Async Thunks
export const fetchStepStatus = createAsyncThunk('financialplan/fetchStepStatus', async (financialplanId, { rejectWithValue }) => {
    try{
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/fetch-Status`, 
            { FinanicialplanId: financialplanId },
            { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('Token')}` }}
        );

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const fetchOnboarding = createAsyncThunk('financialplan/fetchOnboarding', async (_, { rejectWithValue }) => {
    try{
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/onboarding-fetch`, 
            { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('Token')}` }}
        );

        return response.data;
    }
    catch (error) {
        return rejectWithValue(error);
    }
});

// Slice
const planSlice = createSlice({
    name: "financialplan",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchStepStatus.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchStepStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.stepStatus = action.payload;
            })
            .addCase(fetchStepStatus.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchOnboarding.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchOnboarding.fulfilled, (state, action) => {
                state.loading = false;
                state.onboardingData = action.payload;
            })
            .addCase(fetchOnboarding.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default planSlice.reducer;