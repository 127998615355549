import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async Thunks
export const fetchReport = createAsyncThunk('report/fetchReport', async ({ financialplanId, step },{ rejectWithValue }) => {
    try{
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/fetch-report/${step}`,
            { FinanicialplanId: financialplanId },
            { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('Token')}` }}
        );

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

// Slice
const reportSlice = createSlice({
    name: 'report',
    initialState: {
        Graph: [],
        Items: [],
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchReport.fulfilled, (state, action) => {
                state.Graph = action.payload.Graph;
                state.Items = action.payload.Items;
            })
            .addCase(fetchReport.rejected, (state, action) => {
                state.error = action.payload;
            });
    }
});

export default reportSlice.reducer;
