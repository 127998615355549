// Libraries
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { EditOutlined , CloseOutlined } from '@ant-design/icons';

// Components
import { Spin } from "antd";

// Context
import { useSetupContext } from "../../Context/index";

const AISelectionTool = () => {
  // Context
  const {
    currentQuestion,
    currentResponses,
    handleCurrentResponse,
    PushQuestions,
    PopQuestions,
  } = useSetupContext();

  // Custom States
  const [Options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [Add, setAdd] = useState(false);
  const [UserInput, setUserInput] = useState("");
  const [editingIndex, setEditingIndex] = useState(null);
  const [editValue, setEditValue] = useState("");

  const handleEdit = (index, value) => {
    setEditingIndex(index);
    setEditValue(value);
  };

  // Selectors
  const Onboarding = useSelector((state) => state.plan.onboardingData);

  // Functions
  const handleAddOption = () => {
    setOptions([...Options, UserInput]);

    let Response = Array.isArray(currentResponses) ? currentResponses : [];
    const updatedResponse = [...Response, { Response: UserInput }];
    handleCurrentResponse(updatedResponse);

    setAdd(false);
    setUserInput("");
  };

  const handleSelection = (option) => {
    let Response = Array.isArray(currentResponses) ? currentResponses : [];
    const isSelected = Array.isArray(Response) && Response.find((res) => res.Response === option);

    let updatedResponse;
    if (isSelected) {
      updatedResponse = Response.filter((res) => res.Response !== option);

      if (currentQuestion.followuptype === "Repeated") {
        const QuestionsPop = currentQuestion.followupQuestions.map(
          (question) => ({
            ...question,
            Keyelement: option,
            ...(currentQuestion.Keyelement && {
              FactorKey: currentQuestion.Keyelement,
            }),
          })
        );

        PopQuestions(QuestionsPop);
      } else {
        if (updatedResponse.length === 0) {
          PopQuestions(currentQuestion.followupQuestions);
        }
      }
    } else {
      updatedResponse = [...Response, { Response: option }];

      if (currentQuestion.followuptype === "Repeated") {
        const QuestionsPush = currentQuestion.followupQuestions.map(
          (question) => ({
            ...question,
            Keyelement: option,
            ...(currentQuestion.Keyelement && {
              FactorKey: currentQuestion.Keyelement,
            }),
          })
        );

        PushQuestions(QuestionsPush);
      } else {
        if (Response.length === 0) {
          PushQuestions(currentQuestion.followupQuestions);
        }
      }
    }

    handleCurrentResponse(updatedResponse);
  };

  const handleSuggestions = async () => {
    try {
      setLoading(true);

      console.log("Onboarding", Onboarding);
      const prompt = currentQuestion.AI_Prompt.replace(
        "[BUSINESS_Location]",
        Onboarding.ONBOARDING_SELECTED_COUNTRY
      )
        .replace("[BUSINESS_DESCRIPTION]", Onboarding.ONBOARDING_BUSSINES_DES)
        .replace(
          "[BUSINESS_CLASSIFICATION]",
          Onboarding.ONBOARDING_SELECTED_AiSUGGESTION
        )
        .replace("[BUSINESS_NAME]", Onboarding.ONBOARDING_BUSSINES_NAME)
        .replace("[BUSINESS_REVENEUE_EXPENSES]", currentQuestion?.Keyelement)
        .concat(
          "\n\nEnsure that exactly four group names are provided. Do not provide more or less than four group names."
        );

      console.log("prompt", prompt);

      const result = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "chatgpt-4o-latest",
          messages: [{ role: "user", content: prompt }],
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );

      let suggestions = result.data.choices[0].message.content;

      // Remove unwanted characters and split into suggestions
      suggestions = suggestions
        .replace(/[\[\]"']/g, "") // Remove brackets, quotes
        .replace(/\d+\.\s*/g, "") // Remove numbers and following spaces
        .trim(); // Remove leading/trailing whitespace

      // Handle JSON formatted suggestions
      try {
        suggestions = JSON.parse(suggestions);
      } catch (e) {
        // If not JSON, split suggestions into an array based on newlines or commas
        suggestions = suggestions
          .split(/[\n,]+/)
          .map((s) => s.trim())
          .filter((s) => s && s !== "```");
      }

      // Filter out invalid suggestions
      suggestions = suggestions.filter(
        (s) => s && s !== "```" && s !== "```json"
      );

      // Limit to 5 items
      suggestions = suggestions.slice(0, 5);

      console.log("suggestions", suggestions);

      // Merge new suggestions with existing responses without duplicates
      const existingResponses =
        currentResponses?.map((res) => res.Response) || [];
      const mergedOptions = [
        ...new Set([...suggestions, ...existingResponses]),
      ];

      setOptions(mergedOptions);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleSuggestions();
  }, [currentQuestion]);

  const handleSave = (index) => {
    let Response = Array.isArray(currentResponses) ? currentResponses : [];

    const updatedOptions = [...Options];
    const oldOption = updatedOptions[index];
    updatedOptions[index] = editValue || 'Empty';

    let updatedResponse = Response.filter((res) => res.Response !== oldOption);
    updatedResponse = [...updatedResponse, { Response: editValue }];

    const popQuestions = currentQuestion?.followupQuestions?.map((question) => ({
      ...question,
      Keyelement: oldOption,
      ...(currentQuestion.Keyelement && { FactorKey: currentQuestion.Keyelement }),
    }));

    const pushQuestions = currentQuestion?.followupQuestions?.map((question) => ({
      ...question,
      Keyelement: editValue,
      ...(currentQuestion.Keyelement && { FactorKey: currentQuestion.Keyelement }),
    }));

    PopQuestions(popQuestions);
    PushQuestions(pushQuestions);

    setOptions(updatedOptions);
    setEditingIndex(null);
    handleCurrentResponse(updatedResponse);
  };

  const handleCancel = () => {
    setEditingIndex(null);
    setEditValue('');
  };

  return (
    <div className="d-flex w-100 flex-wrap gap-1">
      <button className="btn btn-regenerate" onClick={handleSuggestions}>
        {loading ? <Spin /> : "Re-generate Options"}
      </button>
      <div className="d-flex w-100 flex-wrap gap-1"> 
        {Options.map((option, index) => <>
          {editingIndex  === index ? <div className=" mb-2 button-wrapper">
           <input key={index}
              type="text"
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
              onBlur={() => handleSave(index)}
              // onKeyPress={(e) => handleKeyPress(e, index)}
              // className="edit-input"
              className="btn btn-choice "
              autoFocus
              
            />
           <button
              className="absolute edit-button bgclose"
              onClick={() => handleCancel(index, option)}
            >
              <CloseOutlined className="cancelBTn" style={{ fontSize: '18px' }}/>
            </button>
            </div>
           : 
          <div className="mb-2  button-wrapper" key={index}>
            <button
              className={`btn btn-choice ${
                Array.isArray(currentResponses) &&
                currentResponses.find((res) => res.Response === option)
                  ? "selected"
                  : ""
              }`}
              onClick={() => handleSelection(option)}
            >
              {option}
            </button>
            <button
              className="absolute edit-button bgedit"
              onClick={() => handleEdit(index, option)}
            >
              <EditOutlined className="EditBTn" style={{ fontSize: '18px' }}/>
            </button>
          </div>
          }</>
        )}
        {currentQuestion?.UserEdit === "true" && (
          <>
            {Add ? (
              <div
                className="mb-2"
                style={{ position: "relative", width: "100%" }}
              >
                <input
                  type="text"
                  onChange={(e) => setUserInput(e.target.value)}
                  placeholder="Enter your response"
                  required
                  style={{
                    border: "2px solid #EE6C4D",
                    color: "#EE6C4D",
                    padding: "10px",
                    borderRadius: "32px",
                    height: "50px",
                    width: "calc(100% - 150px)",
                    boxSizing: "border-box",
                    marginRight: "10px",
                  }}
                />
                <button
                  onClick={() => setAdd(false)}
                  style={{
                    backgroundColor: "#f5f5f5",
                    border: "1px solid #EE6C4D",
                    color: "#EE6C4D",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    cursor: "pointer",
                    marginRight: "10px",
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={handleAddOption}
                  style={{
                    backgroundColor: "#EE6C4D",
                    border: "none",
                    color: "#fff",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    cursor: "pointer",
                  }}
                >
                  Save
                </button>
              </div>
            ) : (
              <button className="btn btn-choice" onClick={() => setAdd(true)}>
                Add Option
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AISelectionTool;
