// Libraries
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Recharts
import {
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  LabelList,
  BarChart,
  Bar,
  CartesianGrid,
  Legend
} from 'recharts';

// Antd
import { Table, Spin, message, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

// Styles
import '../../../../../../../assets/styles/system/ReportPage.scss';
import '../../../../../../../assets/styles/system/main.scss';

// Redux
import { fetchReport } from '../../../../../../../Store/Features/reportSlice';

const ReportPage = () => {
  // States
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState(2025);

  // Refs
  const tableContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)

  const handleMouseDown = (e) => {
    setIsDragging(true)
    setStartX(e.pageX - tableContainerRef.current.offsetLeft)
    setScrollLeft(tableContainerRef.current.scrollLeft)
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  const handleMouseMove = (e) => {
    if (!isDragging) return
    e.preventDefault()
    const x = e.pageX - tableContainerRef.current.offsetLeft
    const walk = (x - startX) * 2 // Adjust scrolling speed
    tableContainerRef.current.scrollLeft = scrollLeft - walk
  }

  useEffect(() => {
    const scrollContainer = tableContainerRef.current
    if (scrollContainer) {
      scrollContainer.addEventListener("mousemove", handleMouseMove)
      scrollContainer.addEventListener("mouseup", handleMouseUp)
      scrollContainer.addEventListener("mouseleave", handleMouseUp)
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("mousemove", handleMouseMove)
        scrollContainer.removeEventListener("mouseup", handleMouseUp)
        scrollContainer.removeEventListener("mouseleave", handleMouseUp)
      }
    }
  }, [isDragging, startX, scrollLeft])

  // Selectors
  const { Graph, Items } = useSelector((state) => state.report);

  // Fetch Report
  const { title } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handl_Fetch_Report = async () => {
      try {
        setLoading(true);
        await dispatch(fetchReport({ financialplanId: 1, step: title })).unwrap();
      } catch (error) {
        message.error('Error fetching report');
      } finally {
        setLoading(false);
      }
    };
    handl_Fetch_Report();
  }, [dispatch, title]);

  const loadingIcon = (
    <LoadingOutlined style={{ fontSize: 48, color: '#2c3e50' }} spin />
  );

  if (loading) {
    return (
      <div className="spinner-container">
        <Spin indicator={loadingIcon} />
      </div>
    );
  }

  // Check if Graph or Items are null or empty
  if (!Graph || !Graph.Items || Graph.Items.length === 0 || !Items || Items.length === 0) {
    return (
      <div className="system-main-container">
        <span className='header-page-questions'>No data available for the selected report</span>
      </div>
    );
  }

  // Build 12 months of data for the selected year
  const items = Graph.Items.map((item) => ({
    title: item.Title,
    total: item.Total.slice((year - 2025) * 12, (year - 2025 + 1) * 12).map(Math.round),
  }));

  const data = Array.from({ length: 12 }, (_, index) => {
    const monthValue = items.reduce(
      (sum, item) => sum + (item.total[index] || 0),
      0
    );
    return {
      // X-axis label
      name: new Date(year, index, 1).toLocaleString('default', {
        month: 'short',
        year: 'numeric',
      }),
      total: Math.round(monthValue),
    };
  });

  // Build yearly data for the second chart
  const fullData = [2025, 2026, 2027].map((targetYear) => {
    const slice = Graph.Graph.slice(
      (targetYear - 2025) * 12,
      (targetYear - 2025 + 1) * 12
    );
    const sumVal = slice.reduce((acc, val) => acc + val, 0);
    return { name: targetYear.toString(), value: Math.round(sumVal) };
  });

  // Render nested subitems in the table
  const renderSubItems = (subItems) => {
    const subItemColumns = [
      {
        title: '',
        dataIndex: 'title',
        key: 'title',
        fixed: 'left',
        
      },
      ...Array.from({ length: 36 }, (_, i) => ({
        title: '',
        dataIndex: `${i}`,
        key: `${i}`,
      })),
    ];

    const subItemData = subItems.map((subItem, index) => {
      const rowData = { key: index, title: subItem.Title, Items: subItem.Items };
      subItem.Total.forEach((value, i) => {
        rowData[`${i}`] = Math.round(value);
      });
      return rowData;
    });

    return (
      <div style={{ marginBottom: '0', marginLeft: '0' }}>
        <Table
        className='text-center'
          columns={subItemColumns}
          dataSource={subItemData}
          pagination={false}
          style={{ marginBottom: '0', marginLeft: '0' }}
          components={{
            header: {
              wrapper: (props) => <thead style={{ display: 'none' }} {...props} />,
            },
          }}
        />
      </div>
    );
  };

  // Main table rendering
  const renderItems = () => {
    const itemsArray = Array.isArray(Items) ? Items : [Items];

    return itemsArray.map((item, index) => {
      const itemColumns = [
        {
          title: `${item.Title}`,
          dataIndex: 'title',
          key: 'title',
          width: 150,
          fixed: 'left'
        },
        ...Array.from({ length: 36 }, (_, i) => {
          const displayYear = 2025 + Math.floor(i / 12);
          return {
            title: (
              <div>
                <div className="text-center">
                  {new Date(displayYear, i % 12, 1).toLocaleString('default', {
                    month: 'short',
                  })}
                </div>
                <div className="text-center">{displayYear}</div>
              </div>
            ),
            dataIndex: `${i}`,
            key: `${i}`,
            width: 100,
          };
        }),
      ];

      if (item.Items.length === 0) return null;

      const itemData = item.Items.map((subItem, subIndex) => {
        const rowData = { key: subIndex, title: subItem.Title, Items: subItem.Items };
        subItem.Total.forEach((value, i) => {
          rowData[`${i}`] = Math.round(value);
        });
        return rowData;
      });

      return (
        <div style={{ marginBottom: '20px' }} key={index}>
          <Table
          className='text-center'
            key={index}
            columns={itemColumns}
            // scroll={{ x: 'max-content' }}
            dataSource={itemData}
            pagination={false}
            expandable={{
              expandedRowRender: (record) =>
                record.Items ? renderSubItems(record.Items) : null,
              rowExpandable: (record) => !!record.Items,
              defaultExpandAllRows: true,
            }}
          />
        </div>
      );
    });
  };

  // Navigation
  const handleNavigate = () => {
    const formattedTitle = title.toLowerCase().replace(/\s+/g, '-');
    navigate(`/User/Financials-setup/${formattedTitle}`);
  };

  // Scroll to specific year's columns in table
  const handleScrollToYear = (targetYear) => {
    setYear(targetYear); // update chart year

    if (tableContainerRef.current) {
      const offsetYear = targetYear - 2025;
      const columnsBefore = offsetYear * 12;
      // Adjust if your column widths differ:
      const offsetPx = columnsBefore * 90;

      tableContainerRef.current.scroll({
        left: offsetPx,
        behavior: 'smooth',
      });
    }
  };



  return (
    <div className="system-main-container">
      <span className="header-page-questions">
        {title.charAt(0).toUpperCase() + title.slice(1).toLowerCase()} Report
      </span>

      <div className="container shadow p-3 mb-3 rounded">
        <div className="row">
          <div className="col-md-8 mb-3">
            <ResponsiveContainer width="100%" height={400}>
              <LineChart
                data={data}
                margin={{ top: 40, right: 30, left: 50, bottom: 50 }} // Adjust left margin
              >
                {/* Grid Lines */}
                <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />

                {/* X-Axis */}
                <XAxis
                  dataKey="name"
                  tick={{ fontSize: 12, angle: -45, textAnchor: 'end' }}
                  height={60}
                  interval={0} // Ensure all 12 months are plotted
                />

                {/* Y-Axis */}
                {/* <YAxis
                  tick={{ fontSize: 12 }}
                  tickFormatter={(value) => `$${value.toLocaleString()}`}
                /> */}

                <Tooltip
                  contentStyle={{ backgroundColor: '#fff', borderRadius: '5px', height: '40px' }}
                  labelStyle={{ fontWeight: 'bold' }}
                  formatter={(val) => `$${val.toLocaleString()}`}
                  labelFormatter={(label) => `${label}`}
                />

                {/* Add Bar with low opacity */}
                <Bar dataKey="total" fill="#3D5A80" fillOpacity={1} barSize={20} />

                {/* Line */}
                <Line
                  type="monotone"
                  dataKey="total"
                  name="Total"
                  stroke="#3D5A80"
                  strokeWidth={3}
                  dot={{ r: 5 }}
                  activeDot={{ r: 8 }}
                  isAnimationActive={true}
                  animationBegin={0}
                  animationDuration={1000}
                >
                  <LabelList
                    dataKey="total"
                    position="top"
                    offset={20} // Add space between the text and the line dots
                    style={{ fontSize: 12, fill: '#3D5A80', fontWeight: 'bold' }}
                    formatter={(val) => `$${val.toLocaleString()}`}
                  />
                </Line>
              </LineChart>
            </ResponsiveContainer>
            <div className="toggle-container">
      <button
        className={`toggle-button ${year === 2025 ? 'active' : ''}`}
        style={{ backgroundColor: year === 2025 ? '#F37761' : '',
          fontFamily: 'bold'
         }}
        onClick={() => handleScrollToYear(2025)}
      >
        2025
      </button>
      <button
        className={`toggle-button ${year === 2026 ? 'active' : ''}`}
       // onClick={() => handleInput("Type", 'Per Year')}
        onClick={() => handleScrollToYear(2026)}
      >
        2026
      </button>
      <button
        className={`toggle-button ${year === 2027 ? 'active' : ''}`}
       // onClick={() => handleInput("Type", 'Per Year')}
        onClick={() => handleScrollToYear(2027)}
      >
        2027
      </button>
    </div>
            {/* <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '10px',
                marginTop: '10px',
              }}
            >
              <Button
                type={year === 2025 ? 'primary' : 'default'}
                onClick={() => handleScrollToYear(2025)}
                style={{ minWidth: '80px' }}
              >
                2025
              </Button>
              <Button
                type={year === 2026 ? 'primary' : 'default'}
                onClick={() => handleScrollToYear(2026)}
                style={{ minWidth: '80px' }}
              >
                2026
              </Button>
              <Button
                type={year === 2027 ? 'primary' : 'default'}
                onClick={() => handleScrollToYear(2027)}
                style={{ minWidth: '80px' }}
              >
                2027
              </Button>
            </div> */}
          </div>

          {/* --- SECOND CHART: total per year as a bar chart --- */}
          <div className="col-md-4 mb-3">
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                data={fullData}
                margin={{ top: 40, right: 30, left: 20, bottom: 50 }}
              >
                {/* Grid Lines */}
                <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />

                {/* X-Axis */}
                <XAxis
                  dataKey="name"
                  tick={{ fontSize: 12 }}
                  height={60}
                />

                {/* Y-Axis */}
                <YAxis
                  tick={{ fontSize: 12 }}
                  tickFormatter={(value) => `$${value.toLocaleString()}`}
                />

                {/* Tooltip */}
                <Tooltip
                  contentStyle={{ backgroundColor: '#fff', borderRadius: '5px' ,height: '100%' , color : '#3D5A80'  }}
                  labelStyle={{ fontWeight: 'bold' }}
                  formatter={(val) => `$${val.toLocaleString()}`}
                  labelFormatter={(label) => `${label}`}
                />

                {/* Bars */}
                <Bar
                  dataKey="value"
                  name="Total"
                  fill="#3D5A80"
                  barSize={50}
                  radius={[10, 10, 0, 0]}
                >
                  {/* Labels on Bars */}
                  <LabelList
                    dataKey="value"
                    position="top"
                    style={{ fontSize: 12, fill: '#3D5A80', fontWeight: 'bold' }}
                    formatter={(val) => `$${val.toLocaleString()}`}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '10px',
                marginTop: '10px',
              }}
            >
              <Button onClick={handleNavigate}>Setup</Button>
            </div>
          </div>
        </div>

        <div
            //  onMouseDown={handleMouseDown}
            //  onMouseMove={handleMouseMove}
            //  onMouseLeave={handleMouseLeaveOrUp}
            //  onMouseUp={handleMouseLeaveOrUp}
            onMouseDown={handleMouseDown}
          className="table-scroll-container"
          style={{
            maxWidth: "100%",
            overflowX: "scroll",
            whiteSpace: "nowrap",
            cursor: isDragging ? "grabbing" : "grab",
            userSelect: "none",
            marginTop: '20px'
          }}
          ref={tableContainerRef}
        >
          {renderItems()}
        </div>
      </div>
    </div>
  );
};

export default ReportPage;
