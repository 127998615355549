import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const RedirectRoute = () => {
    // Mian States
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(false);

    // Client States
    // const [clientState, setClientState] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const checkRedirect = async () => {
            const accessToken = localStorage.getItem('Token');
            if (!accessToken) {
                setUser(null);
                setIsLoading(false);
                return;
            }

            try {
                const decodedToken = jwtDecode(accessToken);
                const currentTime = Date.now() / 1000;

                if (decodedToken.exp < currentTime) {
                    setUser(false);
                    localStorage.removeItem('Token');
                } else {
                    setUser(true);
                    // if(decodedToken.USR_VERIFY){
                    //     if(decodedToken.USR_Status){
                    //         setClientState(2);
                    //     } else {
                    //         setClientState(1);
                    //     }
                    // } else {
                    //     setClientState(0);
                    // }
                }
            } catch (error) {
                setUser(null);
                // setClientState(null);
            } finally {
                setIsLoading(false);
            }
        };

        checkRedirect();
    }, []);

    useEffect(() => {
        if (!isLoading) {
            if (user) {
                navigate('/User');
                // if(clientState === 2){
                // } else if(clientState === 1){
                //     navigate('/User/Onboarding');
                // } else {
                //     navigate('/Home/verify');
                // }
            } else {
                navigate('/Home');
            }
        }
    }, [user, isLoading, navigate]);

    return null;
};

export default RedirectRoute;