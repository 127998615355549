// Libraries
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Lottie from 'lottie-react';

// Common
import steps from '../Common/Steps.json';

// Styles
import '../../../../../../../assets/styles/system/financialSetup.scss';
import arrows from '../../../../../../../assets/lottie/arrow.json';
import arrowsVertical from '../../../../../../../assets/lottie/arrowsVertical.json';

const SetupMain = () => {
    const [isMobileBig, setIsMobileBig] = useState(window.innerWidth <= 1496);
    const [isMobileSmall, setIsMobileSmall] = useState(window.innerWidth <= 925);

    const { stepStatus } = useSelector((state) => state.plan);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsMobileBig(window.innerWidth <= 1496);
            setIsMobileSmall(window.innerWidth <= 925);
        };
    
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const formatTitleForUrl = (title) => {
        return title.toLowerCase().replace(/\s+/g, '-');
    };
    
    const handleNavigate = (title) => {
        const formattedTitle = formatTitleForUrl(title);
        navigate(`/User/Financials-setup/${formattedTitle}`);
    };

    return (
        <>
            <div className='financial-header'>
                <h2>Financials Setup</h2>
                <p>The Financials Setup Assistant simplifies the process of adding financial data. By using this tool, existing data will be reset and new data will be generated based on your responses to the questions. The data can then be fine-tuned and adjusted as needed. It is possible to skip this step and manually input all financial information.</p>
            </div>
            <div className="scrollable-buttons-container-setup">
                {steps.map((stepItem, index) => {
                    return (
                        <div className='row mt-3' key={index}>
                            <div className={isMobileSmall ? 'w-75 mb-1' : 'col-2'}>
                                <div 
                                className={`setupStyle d-flex justify-content-center align-items-center`}
                                onClick={() => handleNavigate(stepItem.title)}
                                // style={{ cursor: 'pointer' }}
                                >
                                    <div>
                                        <h3>{stepItem.step}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-9 card-style-finicalsetup"  
                              onClick={() => handleNavigate(stepItem.title)}
                              style={{ cursor: 'pointer' }}
                            >
                                <div className='row'>
                                    <div className={isMobileSmall ? 'text-center' : 'col-7'}>
                                        <h4>{stepItem.title}</h4>
                                    </div>
                                    <div className={isMobileSmall ? 'w-100' : 'col-5'}>
                                        <button 
                                            className={
                                                stepStatus[stepItem.title] === "Completed"
                                                ? 'btn btn-setup-finincalgreen'
                                                : stepStatus[stepItem.title] === "Not Completed"
                                                ? 'btn btn-setup-finincalyellow'
                                                : 'btn btn-setup-finincalred'
                                            }
                                            onClick={() => handleNavigate(stepItem.title)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {stepStatus[stepItem.title] || stepItem.buttonLabel}
                                        </button>
                                    </div>
                                </div>
                                <p>{stepItem.description}</p>
                            </div>
                            {isMobileBig ? (
                                <Lottie className="arrow-logo-responsive" animationData={arrowsVertical} />
                            ) : (
                                <Lottie className="arrow-logo" animationData={arrows} />
                            )}
                        </div>
                    );
                })}
                <div className='row'>
                <div className="col-11">
                    <button className='mt-3 btn btn-finish-setup w-100'>Continue</button>
                </div>
                </div>
            </div>
        </>
    )
}

export default SetupMain