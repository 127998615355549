// Libraries
import React, { useEffect } from "react";

// Tools
import ComboboxSelectionTool from "../Tools/Dropdown/ComboboxSelectionTool";
import FinanceInputTool from "../Tools/Dropdown/FinanceInputTool";
import SalesInputTool from "../Tools/Dropdown/SalesInputTool";
import CostInputTool from "../Tools/Dropdown/CostInputTool";
import PercentageInputTool from "../Tools/Dropdown/PercentageInputTool";
import CustomerInputTool from "../Tools/Dropdown/CustomerInputTool";
import FinanceCalculatorTool from "../Tools/Dropdown/FinanceCalculatorTool";
import RegularInputTool from "../Tools/Dropdown/RegularInputTool";
import VariableInputTool from "../Tools/Dropdown/VariableInputTool";
import DateInputTool from "../Tools/Dropdown/DateInputTool";
import SalaryInputTool from "../Tools/Dropdown/SalaryInputTool";
import OneSelectionTool from "../Tools/Dropdown/OneSelectionTool";
import ShareInputTool from "../Tools/Dropdown/ShareInputTool";
import FundInputTool from "../Tools/Dropdown/FundInputTool";
import NumberInputTool from "../Tools/Dropdown/NumberInputTool";
import TimeInputTool from "../Tools/Dropdown/TimeInputTool";

// Context
import { useSetupContext } from "../Context/index";

const DropDown = () => {
  const { DropdownQuestions, currentDropdownResponses } = useSetupContext();

  useEffect(() => {
    console.log("DropdownQuestions", DropdownQuestions);
    console.log("currentDropdownResponses", currentDropdownResponses);
  }, [DropdownQuestions, currentDropdownResponses]);

  return (
    <div>
      {Array.isArray(DropdownQuestions) &&
        DropdownQuestions.map((question, index) => (
          <div key={index} className="mb-2">
            <span>{question.title}</span>
            <p>{question.description}</p>
            <div>
              <>
                {question.responsetype === "One-combobox-Selection" && (
                  <ComboboxSelectionTool
                    Index={index}
                    Question={question}
                    Responses={currentDropdownResponses[index]?.Responses}
                  />
                )}
                {question.responsetype === "User-Input-Finance" && (
                  <FinanceInputTool
                    Index={index}
                    Question={question}
                    Responses={currentDropdownResponses[index]?.Responses}
                  />
                )}
                {question?.responsetype === "User-Input-Sales" && (
                  <SalesInputTool
                    Index={index}
                    Question={question}
                    Responses={currentDropdownResponses[index]?.Responses}
                  />
                )}
                {question?.responsetype === "User-Input-Cost" && (
                  <CostInputTool
                    Index={index}
                    Question={question}
                    Responses={currentDropdownResponses[index]?.Responses}
                  />
                )}
                {(question?.responsetype === "User-Input-Percentage" || question?.responsetype === "User-Percentage-Input") && (
                  <PercentageInputTool
                    Index={index}
                    Question={question}
                    Responses={currentDropdownResponses[index]?.Responses}
                  />
                )}
                {question?.responsetype === "User-Input-Customers" && (
                  <CustomerInputTool
                    Index={index}
                    Question={question}
                    Responses={currentDropdownResponses[index]?.Responses}
                  />
                )}
                {question?.responsetype === "Finance-Calculator" && (
                  <FinanceCalculatorTool
                    Index={index}
                    Question={question}
                    Responses={currentDropdownResponses[index]?.Responses}
                  />
                )}
                {question?.responsetype === "Finance-Monthly-Calculator" && (
                  <RegularInputTool
                    Index={index}
                    Question={question}
                    Responses={currentDropdownResponses[index]?.Responses}
                  />
                )}
                {question?.responsetype === "User-Date-Input" && (
                  <DateInputTool
                    Index={index}
                    Question={question}
                    Responses={currentDropdownResponses[index]?.Responses}
                  />
                )}
                {(question?.responsetype === "User-Input-Salary" || question?.responsetype === "User-Salary-Input" )  && (
                  <SalaryInputTool
                    Index={index}
                    Question={question}
                    Responses={currentDropdownResponses[index]?.Responses}
                  />
                )}
                {(question?.responsetype === "User-Input-Variable" || question?.responsetype === "User-Variable-Input") && (
                  <VariableInputTool
                    Index={index}
                    Question={question}
                    Responses={currentDropdownResponses[index]?.Responses}
                  />
                )}
                {question?.responsetype === "One-User-Selection" && (
                  <OneSelectionTool
                    Index={index}
                    Question={question}
                    Responses={currentDropdownResponses[index]?.Responses}
                  />
                )}
                {question?.responsetype === "User-Share-Input" && (
                  <ShareInputTool
                    Index={index}
                    Question={question}
                    Responses={currentDropdownResponses[index]?.Responses}
                  />
                )}
                {question?.responsetype === "User-Fund-Input" && (
                  <FundInputTool
                    Index={index}
                    Question={question}
                    Responses={currentDropdownResponses[index]?.Responses}
                  />
                )}
                {question?.responsetype === "User-Number-Input" && (
                  <NumberInputTool
                    Index={index}
                    Question={question}
                    Responses={currentDropdownResponses[index]?.Responses}
                  />
                )}
                {question?.responsetype === "User-Time-Input" && (
                  <TimeInputTool
                    Index={index}
                    Question={question}
                    Responses={currentDropdownResponses[index]?.Responses}
                  />
                )}
              </>
            </div>
          </div>
        ))}
    </div>
  );
};

export default DropDown;

//User-Date-Input User-Input-Salary
