import React from 'react';
import logo from '../../../assets/images/white-logo.png';
import { HashLink } from 'react-router-hash-link';
import "../../../assets/styles/components/header.scss";
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light sticky-top">
        <div className="container navbar-style mt-2">
          <img src={logo} width="100" height="35" className="d-inline-block align-top" alt="proFormulate logo" />
          <button className="navbar-toggler modern-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav m-auto">
              <li className="nav-item active">
                <Link to="/Home" className="nav-link">Home</Link>
              </li>
              <li className="nav-item active">
                <HashLink smooth to="/#features" className="nav-link">Features</HashLink>
              </li>
              <li className="nav-item active">
                <Link to="/Home/plans" className="nav-link">Plans</Link>
              </li>
              <li className="nav-item active">
                <HashLink smooth to="/#contact" className="nav-link">Contact</HashLink>
              </li>
            </ul>
            <div className="d-flex flex-column flex-lg-row align-items-center">
              <Link to='/Home/login'>
                <button className="btn btn-login-header pr-5 mb-2 mb-lg-0">
                  Login
                </button>
              </Link>
              <Link to='/Home/register'>
                <button className="btn btn-tryNow">
                  Try it now
                </button>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
