import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const ProtectedRoute = ({ children }) => {
  // Auth State
  const [isLoading, setIsLoading] = useState(true);

  // Users States
  const [authenticated, setAuthenticated] = useState(false);
  // const [verified, setVerified] = useState(true);
  // const [status, setStatus] = useState(true);

  // Location
  const location = useLocation();

  useEffect(() => {
    const Access = () => {
      const accessToken = localStorage.getItem('Token');
      if (!accessToken) {
        setAuthenticated(false);
        setIsLoading(false);
        return;
      }

      try {
        const decodedToken = jwtDecode(accessToken);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp < currentTime) {
          setAuthenticated(false);
          localStorage.removeItem('Token');
          return;
        }
        // } else {
        //   // if (!decodedToken.USR_VERIFY) {
        //   //   setVerified(false);
        //   // }

        //   // if (!decodedToken.USR_Status) {
        //   //   setStatus(false);
        //   // }
        // }

        setAuthenticated(true);
      } catch (error) {
        console.error('Invalid token:', error);
        console.log('Token:', accessToken);
        setAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };

    Access();
  }, []);

  // if (!isLoading && authenticated && !verified) {
  //   return <Navigate to="/Home/verify" replace />;
  // }

  // if (!isLoading && !location.pathname === '/User/Onboarding' && authenticated && !status) {
  //   return <Navigate to="/User/Onboarding" replace />;
  // }

  if (!isLoading && location.pathname.startsWith('/User') && !authenticated) {
    console.log('Redirecting to /Home');
    return <Navigate to="/" replace />;
  }

  return children;
}

export default ProtectedRoute;