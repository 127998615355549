// Libraries
import React from 'react'

// Context
import { useSetupContext } from '../../Context/index';

const ShareInputTool = ({ Index, Question, Responses }) => {
  // Context
  const { handleCurrentDropdownResponse } = useSetupContext();

  // Functions
  const handleInput = (field, value) => {
    let Response = Responses ? structuredClone(Responses) : {
      Response: { Shares: '', Value: '', Type: '' }
    };

    if (field === 'Date') {
      value = value ? value.toISOString() : null;
    }

    Response.Response[field] = value;
    handleCurrentDropdownResponse(Index, Response);
  }

  return (
    <div className="d-flex w-full align-items-center mb-2 gap-1">
      <div className="d-flex  align-items-center input-div">
        <span className='flex items-center bg-[#3D5A80] text-white px-2 py-1 rounded-l-md' style={{ backgroundColor: '#3D5A80', color: 'white', padding: '0.55rem', borderTopLeftRadius: '0.25rem', borderBottomLeftRadius: '0.25rem' }}>%</span>
        <input
          type="number"
          inputMode="numeric"
          pattern="[0-9]*"
          value={Responses?.Response?.Shares || ""}
          onChange={(e) => handleInput("Shares", e.target.value)}
          placeholder={"Percentage"}
          className=" w-32 px-3 py-2 border border-gray-300  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          required
        />
      </div>
      <span className="Q8-dollar-icon">Sold for</span>
      <div className="d-flex  align-items-center input-div">
        <span className='flex items-center bg-[#3D5A80] text-white px-2 py-1 rounded-l-md' style={{ backgroundColor: '#3D5A80', color: 'white', padding: '0.55rem', borderTopLeftRadius: '0.25rem', borderBottomLeftRadius: '0.25rem' }}>$</span>
        <input
          type="number"
          inputMode="numeric"
          pattern="[0-9]*"
          value={Responses?.Response?.Value || ""}
          onChange={(e) => handleInput("Value", e.target.value)}
          placeholder={"Cost"}
          className=" w-32 px-3 py-2 border border-gray-300  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          required
        />
      </div>
      <div className="toggle-container">
        <button
          className={`toggle-button ${Responses?.Response?.Type === 'Total' ? 'active' : ''}`}
          onClick={() => handleInput("Type", 'Total') }
        >
          Total
        </button>
        <button
          className={`toggle-button ${Responses?.Response?.Type === 'Per Share' ? 'active' : ''}`}
          onClick={() => handleInput("Type", 'Per Share')}
        >
          Per Share
        </button>
      </div>
    </div>
  )
}

export default ShareInputTool