// Libraries
import React from 'react';
import { Link } from 'react-router-dom';

// Styles
import '../../../assets/styles/components/footer.scss';
import logo from '../../../assets/images/white-logo.png';

const Footer = () => {
  return (
    <div className="footer-container">
    <footer className="footer">
      <div className="footer-content">
        {/* Company Info Section */}
        <div className="footer-info">
          <div className="footer-logo">
            <img src={logo} alt="proFormulate logo" />
          </div>
          <p className="footer-address">
            info@aallie.com
          </p>
        </div>

        {/* Support Section */}
        <div className="footer-section">
          <h3 className="footer-heading">Support</h3>
          <nav className="footer-nav">
            <Link to='/Home/privacy-and-policy'>Privacy and Policy</Link>
            <Link to='/Home/terms-and-conditions'>Terms and Conditions</Link>
          </nav>
        </div>

        {/* Company Section */}
        <div className="footer-section">
          <h3 className="footer-heading">Company</h3>
          <nav className="footer-nav">
            <Link to="/home/plans">Plans</Link>
            <Link to="/home/register">Register</Link>
          </nav>
        </div>
      </div>

      <div className="footer-bottom">
        Copyright © 2024 aallie | Powered by aallie
      </div>
    </footer>
  </div>
    // <div className="footer container py-4">
    //   <div className="row  text-md-left">
    //     <div className="col-12 d-flex justify-content-center flex-column col-md-6 mb-3">
    //       <div className="row d-flex justify-content-center flex-column">
    //         <div className="col-3 col-md-3 mb-3">
    //           <img 
    //             src={logo} 
    //             width="100" 
    //             height="50" 
    //             className="d-inline-block footer-logo align-top" 
    //             alt="proFormulate logo" 
    //           />
    //         </div>
    //         <div className="footer-address col-12 col-md-6 mb-3">
    //           <p className="p-footer">
    //             5500 University Pkwy, San Bernardino, CA 92407, United States. <br />
    //             +1 (951) 407-0422 <br />
    //             info@aallie.com
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="col-4 col-md3 mb-3">
    //       <div className="row">
    //         <div className="col-12 d-flex justify-content-center flex-column support col-md-6 mb-3">
    //           <h5 className='text-center'>Support</h5>
    //           <div className="mt-2 p-footer text-center">
    //             <Link to='/Home/privacy-and-policy'><span>Privacy Policy</span></Link>
    //             <br />
    //             <Link to='/Home/terms-and-conditions'><span>Terms and Conditions</span></Link>
    //           </div>
    //         </div>
    //         <div className="col-12 company d-flex justify-content-center  flex-column col-md-6 mb-3">
    //           <h5 className='text-center'>Company</h5>
    //           <div className="mt-2 p-footer text-center">
    //             <Link to='/Home/plans'><span>Plans</span></Link>
    //             <br />
    //             <Link to='/Home/register'><span>Register</span></Link>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <p className='text-center mt-2 footer-p'>Copyright © 2024 aallie | Powered by aallie</p>
    // </div>
  );
};

export default Footer;
