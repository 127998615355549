// Libraries
import React from "react";
import { DatePicker } from "antd";
import moment from "moment";

// Context
import { useSetupContext } from "../../Context/index";

const DateInputTool = ({ Index, Responses }) => {
  // Context
  const { handleCurrentDropdownResponse } = useSetupContext();
 
  // Functions
  const handleInput = (value) => {
   
    let Response = Responses
      ? structuredClone(Responses)
      : {
        Response: null,
      };

    value = value ? value.toISOString() : null;

    Response.Response = value;
    handleCurrentDropdownResponse(Index, Response);
  };

  return (
    <div className="d-flex w-100 flex-wrap gap-1">
      <DatePicker
        picker="month"
        value={Responses?.Response ? moment(Responses.Response) : null}
        onChange={(date) => handleInput(date)}
        className="w-full appearance-none bg-white px-3 py-2 rounded-lg border transition-all date-input duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        required
        placeholder="MM-YYYY"
        format="MM-YYYY"
      />
    </div>
  );
};

export default DateInputTool;
