// Libraries
import React from 'react'

// Context
import { useSetupContext } from '../../Context/index';

const TimeInputTool = ({ Index, Question, Responses }) => {
    // Context
    const { handleCurrentDropdownResponse } = useSetupContext();

    // Functions
    const handleInput = (field, value) => {
        let Response = Responses ? structuredClone(Responses) : {
            Response: { Period: '', Type: '' }
        };

        Response.Response[field] = value;
        handleCurrentDropdownResponse(Index, Response);
    }

    return (
        <div className="d-flex w-full align-items-center mb-2 gap-1">
            <div className="d-flex  align-items-center input-div">
            <input
                type="number"
                inputMode="numeric"
                pattern="[0-9]*"
                value={Responses?.Response?.Period || ""}
                onChange={(e) => handleInput("Period", e.target.value)}
                placeholder={"e.g 5"}
                className=" w-32 px-3 py-2 border border-gray-300  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
                />
            </div>
            <div className="toggle-container">
                <button
                    className={`toggle-button ${Responses?.Response?.Type === 'Months' ? 'active' : ''}`}
                    onClick={() => handleInput("Type", 'Months') }
                >
                    Months
                </button>
                <button
                    className={`toggle-button ${Responses?.Response?.Type === 'Years' ? 'active' : ''}`}
                    onClick={() => handleInput("Type", 'Years')}
                >
                    Years
                </button>
            </div>
        </div>
    )
}

export default TimeInputTool