// Libraries
import React from 'react'
import { DatePicker } from 'antd';
import moment from 'moment';

// Context
import { useSetupContext } from '../../Context/index';

const FundInputTool = ({Index, Question, Responses}) => {
    // Context
    const { handleCurrentDropdownResponse } = useSetupContext();

    // Functions
    const handleInput = (field, value) => {
        let Response = Responses ? structuredClone(Responses) : {
            Response: { Value: '', Date: null }
        };

        if (field === 'Date') {
            value = value ? value.toISOString() : null;
        }

        Response.Response[field] = value;
        handleCurrentDropdownResponse(Index, Response);
    }

    return (
        <div className="d-flex w-full align-items-center mb-2 gap-1">
            <div className="d-flex align-items-center input-div">
                <span
                    className="flex items-center bg-[#3D5A80] text-white px-2 py-1 rounded-l-md"
                    style={{
                        backgroundColor: '#3D5A80',
                        color: 'white',
                        padding: '0.55rem',
                        borderTopLeftRadius: '0.25rem',
                        borderBottomLeftRadius: '0.25rem',
                    }}
                >
                    $
                </span>
                <input
                    type="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={Responses?.Response?.Value || ""}
                    onChange={(e) => handleInput("Value", e.target.value)}
                    placeholder={"E.g 5000"}
                    className="w-32 px-3 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    required
                />
                <span
                    className="flex items-center bg-[#3D5A80] !text-sm text-white px-2 py-1 rounded-l-md"
                    style={{
                        backgroundColor: '#3D5A80',
                        color: 'white',
                        padding: '0.55rem',
                        borderTopLeftRadius: '0.25rem',
                        borderBottomLeftRadius: '0.25rem',
                    }}
                >
                    $
                </span>
                <DatePicker
                    picker="month"
                    value={Responses?.Response?.Date ? moment(Responses.Response.Date) : null}
                    onChange={(date) => handleInput("Date", date)}
                    className="w-full date-input appearance-none bg-white px-3 py-2 rounded-lg border transition-all duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    required
                    placeholder="MM-YYYY"
                    format="MM-YYYY"
                />
            </div>
        </div>
    )
}

export default FundInputTool