// Libraries
import React from 'react'

// Context
import { useSetupContext } from '../../Context/index';

const CostInputTool = () => {
  // Context
  const { currentResponses, handleCurrentResponse } = useSetupContext();

  // Functions
  const handleInput = (value) => {
    let updatedResponse = { Response: value }
    handleCurrentResponse(updatedResponse);
  };

  return (
    <div className="d-flex w-100 flex-wrap gap-1 input-div">
      <input
        type="number"
        inputMode="numeric"
        pattern="[0-9]*"
        value={currentResponses?.Response || ""}
        onChange={(e) => handleInput(e.target.value)}
        placeholder={"Cost"}
        className=" w-32 px-3 py-2 border border-gray-300  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        required
      />
      <span className="Q8-dollar-icon ">$</span>
    </div>
  )
}

export default CostInputTool