// Libraries
import React, { Fragment } from 'react';

// Components
import Home from '../Components/Home';
import Model from '../Components/Model';
import Services from '../Components/Services';
import Features from '../Components/Features';
import ContactUs from '../Components/ContactUs';
import Footer from '../Components/Footer';

const Main = () => {
  return (
    <Fragment>
      <Home />
      {/* <Model />
      <Services/> */}
      <div id="features">
        <Features/>
      </div>
      {/* <FAQP /> */}
      <div id="contact">
        <ContactUs />
      </div>
      <Footer />
    </Fragment>
  );
};

export default Main;
