// Libraries
import React from 'react'

// Context
import { useOnboardingContext } from '../Context/index'

// Styles
import '../../../../../assets/styles/components/onBoarding.scss';

const StepOne = () => {
    const { formData, handleFormData } = useOnboardingContext();

    return (
        <div className="text-center q1-onboarding">
            <h1>Why are you creating a financial plan?</h1>
            <p>
                The option you choose will not alter the structure of your plan; it just helps us to better understand your
                goals.
            </p>
            {['Validate Idea', 'Launch Startup', 'Drive Growth'].map((option) => (
                <button
                key={option}
                className={`btn btn-onBorading-Answers mb-2 ${
                    formData.ONBOARDING_SELECTED_OPTION === option ? 'selected' : ''
                }`}
                onClick={() => handleFormData('ONBOARDING_SELECTED_OPTION', option)}
                >
                <span>{option}</span>
                {/* <br /> */}
                {option === 'Validate Idea'
                    ? 'To transform a business concept or idea into a structured plan...'
                    : option === 'Launch Startup'
                    ? 'To create a roadmap for launching, scaling or funding a new business...'
                    : 'To develop strategies for expanding operations...'}
                </button>
            ))}
        </div>
    )
}

export default StepOne