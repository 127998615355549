// Libraries
import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';

// Prompts
import prompts from '../Common/Prompts.json';

const OnboardingContext = createContext();

export const useOnboardingContext = () => useContext(OnboardingContext);

export const OnboardingProvider = ({ children }) => {
    const [formData, setFormData] = useState({
        ONBOARDING_SELECTED_OPTION: '',
        ONBOARDING_SELECTED_COUNTRY: 'United States',
        ONBOARDING_SELECTED_STATE: '',
        ONBOARDING_BUSSINES_DES: '',
        ONBOARDING_BUSSINES_NAME: '',
        ONBOARDING_SELECTED_AiSUGGESTION: '',
    });

    const [loadingAI, setLoadingAI] = useState(false);

    const handleFormData = (key, value) => {
        setFormData({
            ...formData,
            [key]: value,
        });
    }

    const handleLoadSuggestions = async (type) => {
        if(type !== 'ai') setLoadingAI(true);
        
        if (!formData.ONBOARDING_BUSSINES_DES) {
            return { error: 'Please provide a business description first.' };
        }
    
        const promptTemplate = type === 'ai' ? prompts.loadAiSuggestions : prompts.loadBusinessNameSuggestions;
        
        console.log(formData.ONBOARDING_BUSSINES_DES)
        const prompt = promptTemplate.replace(
            '{business_description}',
            formData.ONBOARDING_BUSSINES_DES
        );
        
        try {
            const result = await axios.post(
              'https://api.openai.com/v1/chat/completions',
              {
                model: "chatgpt-4o-latest",
                messages: [{ role: 'user', content: prompt }],
              },
              {
                headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
                  'Content-Type': 'application/json',
                },
              }
            );
        
            const suggestions = result.data.choices?.[0]?.message?.content?.split('\n') ?? [];
            const filteredSuggestions = suggestions
              .map((suggestion) => suggestion.replace(/^-/, '').trim())
              .filter((suggestion) => suggestion);
        
            return filteredSuggestions;
        } catch (error) {
            console.error(`Failed to load ${type} suggestions:`, error);
            return { error: `Failed to load ${type} suggestions. Please try again later.` };
        } finally {
            setLoadingAI(false);
        }
    }

    const handleSubmitQ = async () => {
        try{
            console.log("Token", localStorage.getItem('Token'));
            const result = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/auth/onboarding`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if(result.status === 201){
                setFormData([]);
                // console.log("Token", result.data.token);
                // const decoded = jwtDecode(result.data.token);
                // console.log("Decoded", decoded);
                // localStorage.setItem("Token", result.data.token);
                return {
                    status: true,
                    message: 'Onboarding form submitted successfully'
                }
            } else {
                return {
                    status: false,
                    error: 'Failed to submit onboarding form. Please try again later.'
                }
            }
        } catch (error) {
            return { error: error.message || 'Failed to submit onboarding form. Please try again later.' };
        }
    }

    return (
        <OnboardingContext.Provider value={{
            formData,
            setFormData,
            loadingAI,
            handleLoadSuggestions,
            handleFormData,
            handleSubmitQ,
        }}>
            {children}
        </OnboardingContext.Provider>
    );
}