// Libraries
import React from 'react'

// Context
import { OnboardingProvider } from './Context/index'

// Components
import Header from './Components/Header'
import Questionner from './Components/Questionner'

const Onboarding = () => {
  return (
    <>
      <Header/>
      <div className="layout-contenttt onboarding-layout">
        <OnboardingProvider>
          <Questionner/>
        </OnboardingProvider>
      </div>
    </>
  )
}

export default Onboarding