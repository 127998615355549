// Libraries
import React from "react";
import { DatePicker } from "antd";
import moment from "moment";

// Context
import { useSetupContext } from "../../Context/index";

const DateInputTool = () => {
    // Context
    const { currentResponses, handleCurrentResponse } = useSetupContext();

    // Functions
    const handleInput = (value) => {
        value = value ? value.toISOString() : null;

        let updatedResponse = { Response: value };
        handleCurrentResponse(updatedResponse);
    };

    return (
        <div className="d-flex w-100 flex-wrap gap-1">
            <DatePicker
                picker="month"
                value={currentResponses?.Response ? moment(currentResponses?.Response) : null}
                onChange={(date) => handleInput(date)}
                className="w-full appearance-none bg-white px-3 py-2 date-input rounded-lg border transition-all duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
                placeholder="MM-YYYY"
                format="MM-YYYY"
            />
        </div>
    );
};

export default DateInputTool;