// Libraries
import React from 'react'

// Tools
import VariableInput from '../Common/VariableInput';

// Context
import { useSetupContext } from '../../Context/index';

const CostInputTool = ({Index, Question, Responses}) => {
  // Context
  const { handleCurrentDropdownResponse } = useSetupContext();

  // Functions
    const handleInput = (value) => {
        let Response = Responses ? structuredClone(Responses) : {
            Response: '',
            VariableInputFlag: false
        };

        Response.Response = value;
        handleCurrentDropdownResponse(Index, Response);
    }

    const handleVariable = () => {
        let Response = Responses ? structuredClone(Responses) : {
            Response: '',
            VariableInputFlag: false
        };

        Response.VariableInputFlag = !Response.VariableInputFlag;
        if (Response.VariableInputFlag) {
            Response.Response = [];
        } else {
            Response.Response = '';
        }

        handleCurrentDropdownResponse(Index, Response);
    };

    const handleInputVariable = (value) => {
        let Response = {...Responses};
        if(!Response){
            Response = {
                Response: [],
                VariableInputFlag: true
            }
        }

        Response.Response = value;
        handleCurrentDropdownResponse(Index, Response);
    };

    return (
        <div className='d-flex align-items-center gap-2'>
            {!Responses?.VariableInputFlag && (
                <div className="d-flex  flex-wrap gap-1 input-div">
                    <input
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        value={Responses?.Response || ""}
                        onChange={(e) => handleInput(e.target.value)}
                        placeholder={"Cost"}
                        className="Q8input-response"
                        required
                    />
                    <span className="Q8-dollar-icon ">$</span>
                </div>
            )}
            {Question.VariableEnable === "true" && (
                <div style={{display: "flex" , flexDirection: "column"}}>
                    <label className="toggle-switch">
            <input
              type="checkbox"
              checked={Responses?.VariableInputFlag || false}
              onChange={() =>  handleVariable()}
          
            />
            <span className="toggle-slider">
              <span className="toggle-knob" />
            </span>
            <span className="toggle-label">Variable Input</span>
          </label>
    
                    {Responses?.VariableInputFlag && (
                        <VariableInput
                            Data={Responses?.Response}
                            onDataChange={(value) => handleInputVariable(value)}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default CostInputTool;