// Libraries
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

// CSS Components
import { message, Steps, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

// Components
import Card from '../Components/Card';

// Redux
import { fetchQuestionner, SaveResponse, SubmitResponse, setStep } from '../../../../../../../Store/Features/QuestionnerSlice';

// Context
import { useSetupContext } from '../Context';

const QuestionnerPage = () => {
  // Selectors
  const { loading, currentStep } = useSelector(state => state.questionner);

  // Context
  const { Questions, UserResponses, ResponseChange, setResponseChange, handleConditionlQuestions } = useSetupContext();

  const { title } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { Step } = Steps;
  const loadingIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

  useEffect(() => {
    const handleFetchQuestionner = async () => {
      try {
        await dispatch(fetchQuestionner({ financialplanId: 1, step: title })).unwrap();
      } catch (error) {
        message.error('Error fetching questionner');
      }
    };

    handleFetchQuestionner();
  }, []);

  useEffect(() => {
    const handleResponseChange = async () => {
      if (ResponseChange) {
        try {
          handleConditionlQuestions();
          await dispatch(SaveResponse({ financialplanId: 1, step: title, Responses: UserResponses })).unwrap();
        } catch (error) {
          console.error('Error:', error);
        } finally {
          setResponseChange(false);
        }
      }
    };

    handleResponseChange();
  }, [ResponseChange]);

  const nextStep = () => {
    dispatch(setStep(currentStep + 1));
  };

  const prevStep = () => {
    dispatch(setStep(currentStep - 1));
  }

  const changeStep = (step) => {
    dispatch(setStep(step));
  }
  const Validate = () => {
    for (let i = 0; i < Questions.length; i++) {
      const question = Questions[i];
      const response = UserResponses[i];

      if (!response || isEmpty(response.Responses)) {
        dispatch(setStep(i));
        return false;
      }
      if (question.Dropdown && question.Dropdown.length > 0) {
        for (let j = 0; j < question.Dropdown.length; j++) {
          const dropdownResponse = response.DropdownResponses ? response.DropdownResponses[j] : null;
         
          if (!dropdownResponse || isEmpty(dropdownResponse)) {
            dispatch(setStep(i));
            return false;
          }

       
        }
      }
    }

    return true;
  }

  const handleSubmit = async () => {
    try {
      console.log('UserResponses', UserResponses);
      if (Validate()) {
        const result = await dispatch(SubmitResponse({ financialplanId: 1, step: title, Responses: UserResponses })).unwrap();
        if (result.status === 'success') {
          message.success('Response submitted successfully');
          
            navigate(`/User/Reports/${title}`);
         
        }
      } else {
        message.error('Please fill all the fields');
      }
    } catch {
      message.error('Error submitting response');
    }
  };

  return (
    <div className="row system-main-container">
      <div className="col-12">
        {loading ? (
          <div className="loading-container">
            <Spin indicator={loadingIcon} />
          </div>
        ) : (
          Questions.length > 0 && (
            <>
              <div className="d-flex justify-content-center">
                <span className='header-page-questions'>
                  {title.charAt(0).toUpperCase() + title.slice(1)}
                </span>
              </div>
              <Steps current={currentStep} className="hide-on-small ">
                {Questions.map((question, index) => (
                  <Step 
                    key={question.id}
                    onClick={() => changeStep(index)}
                    status={
                      index <= currentStep ? 'process' : 'wait'
                    }
                  />
                ))}
              </Steps>
              <div className="d-flex flex-column">
                {Questions.length > 0 && (
                  <div className="col-12 ">
                    <Card />
                  </div>
                )}
                <div className="d-flex justify-content-between align-items-center mt-5 ">
                  {currentStep > 0 && (
                    <button
                      className="btn btn-previous-questioner"
                      onClick={prevStep}
                    >
                      Previous
                    </button>
                  )}

                  {currentStep < Questions.length - 1 && (
                    <button
                      className={`btn btn-next-questioner ${currentStep === 0 ? "full-width" : ""
                        }`}
                      onClick={nextStep}
                    >
                      Next
                    </button>
                  )}

                  {currentStep === Questions.length - 1 && (
                    <button
                      className="btn btn-done-questioner"
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      {loading ? "Submitting..." : "Submit"}
                    </button>
                  )}
                </div>
              </div>
            </>
          )
        )}
      </div>
    </div>
  )
}

export default QuestionnerPage