// Libraries
import React from 'react'

// Tools
import VariableInput from '../Common/VariableInput';

// Context
import { useSetupContext } from '../../Context/index';

const FinanceCalculatorTool = ({Index, Question, Responses}) => {
    // Context
    const { handleCurrentDropdownResponse } = useSetupContext();

    // Functions
    const handleInput = (field, value) => {
      let Response = Responses ? structuredClone(Responses) : {
        Response: { Value: '', Type: '' },
        VariableInputFlag: false
      };

      Response.Response[field] = value;
      handleCurrentDropdownResponse(Index, Response);
    }

    const handleVariable = () => {
      let Response = Responses ? structuredClone(Responses) : {
        Response: { Value: '', Type: '' },
        VariableInputFlag: false
      };

      Response.VariableInputFlag = !Response.VariableInputFlag;
      if (Response.VariableInputFlag) {
        Response.Response = [];
      } else {
        Response.Response = { Value: '', Type: '' };
      }

      handleCurrentDropdownResponse(Index, Response);
    };

    const handleInputVariable = (value) => {
      let Response = {...Responses};
      if(!Response){
        Response = {
          Response: [],
          VariableInputFlag: true
        }
      }

      Response.Response = value;
      handleCurrentDropdownResponse(Index, Response);
    };

    return (
        <div className='d-flex align-items-center gap-2'>
            {!Responses?.VariableInputFlag && (
                <div className="d-flex w-100 flex-wrap gap-1">
                    <div className="d-flex align-items-center  input-div">
                        <span>Every</span>
                            
                        <input type="number" inputMode="numeric" pattern="[0-9]*" className=" w-32 px-3 py-2 border border-gray-300  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" placeholder='e.g 10' style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0', borderTopRightRadius: '0.25rem', borderBottomRightRadius: '0.25rem' }} value={Responses?.Response?.Value || ""} onChange={(e) => handleInput("Value", e.target.value)} />
                    </div>
                    {/* <div>
                        <select className="form-select" value={Responses?.Response?.Type || ""} onChange={(e) => handleInput("Type", e.target.value)} required>
                            <option value="" disabled>Select a period</option>
                            <option value="months">Months</option>
                            <option value="weeks">Weeks</option>
                        </select>
                    </div> */}
                     <div className="toggle-container">
      <button
        className={`toggle-button ${Responses?.Response?.Type === 'Per Month' ? 'active' : ''}`}
        onClick={() => handleInput("Type", 'Per Month') }
      >
        Per Month
      </button>
      <button
        className={`toggle-button ${Responses?.Response?.Type === 'Per Year' ? 'active' : ''}`}
        onClick={() => handleInput("Type", 'Per Year')}
      >
        Per Year
      </button>
    </div>
               
                    {/* <div className="period-content">
            <div className="select-wrapper">
              <select
                className="custom-select select-padding"
                value={Responses?.Response?.Type || ""} onChange={(e) => handleInput("Type", e.target.value)} required
              >
                <option value="" disabled>Select a period</option>
                            <option value="months">Months</option>
                            <option value="weeks">Weeks</option>
              </select>
              <svg
                className="select-arrow"
                width="15"
                height="10"
                viewBox="0 0 15 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.72727 0L0 2.72727L7.27273 10L14.5455 2.72727L11.8182 0L7.27273 4.54545L2.72727 0Z"
                  fill="#EE6C4D"
                  fillOpacity="0.5"
                />
              </svg>
            </div>
          </div> */}
                </div>
            )}
            {Question.VariableEnable === "true" && (
                <div className="d-flex justify-content-center gap-2" style={{display: "flex", flexDirection: "column"}}>
                    <label className="toggle-switch">
            <input
              type="checkbox"
              checked={Responses?.VariableInputFlag || false}
              onChange={() =>  handleVariable()}
          
            />
            <span className="toggle-slider">
              <span className="toggle-knob" />
            </span>
            <span className="toggle-label">Variable Input</span>
          </label>
                    
                    {Responses?.VariableInputFlag && (
                        <VariableInput
                            Data={Responses?.Response}
                            onDataChange={(value) => handleInputVariable(value)}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default FinanceCalculatorTool