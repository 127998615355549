// Libraries
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";

// CSS Components
import { Alert, Divider, message, Steps } from 'antd';
import PhoneInput from "react-phone-input-2";
import Lottie from "lottie-react";

// Assets
import "react-phone-input-2/lib/style.css";
import registerLottie from "../../../assets/lottie/Signup.json";
import "../../../assets/styles/pages/register.scss";
import Marquee from 'react-fast-marquee';

// Redux
import { googleLogin, registerUser } from "../../../Store/Features/authSlice";

const Register = () => {
  const { loading, error } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    USR_Username: "",
    USR_Password: "",
    USR_Password_confirmation: "",
    USR_Email: "",
    USR_First_Name: "",
    USR_Last_Name: "",
    USR_Phone: "",
    USR_Phone_country_code: "",
    USR_Google_ID: "",
    USR_Free_Trial: true,
    USR_Country_Code: "US",
    USR_email_verified: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handlePhoneChange = (value, country) => {
    console.log(value, country);
    setFormData((prevFormData) => ({
      ...prevFormData,
      USR_Phone: value,
      USR_Phone_country_code: country.dialCode,
    }));
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const steps = [
    {
      title: "Personal Information",
      content: (
        <>
         
            <div className="col-12">
              <input
                type="text"
                className="form-control"
                name="USR_First_Name"
                placeholder="First name"
                value={formData.USR_First_Name}
                onChange={handleChange}
              />
            </div>
            
            <div className="col-12">
              <input
                type="text"
                className="form-control form-design"
                name="USR_Last_Name"
                placeholder="Last Name"
                value={formData.USR_Last_Name}
                onChange={handleChange}
              />
            </div>
        
        
            <div className="col-12  ">
              <input
                type="text"
                className="form-control form-design"
                name="USR_Username"
                placeholder="Username"
                value={formData.USR_Username}
                onChange={handleChange}
              />
            </div>
          
        </>
      ),
    },
    {
      title: "Account Information",
      content: (
        <>
         
            <div className="col-12">
              <input
                type="email"
                className="form-control form-design"
                name="USR_Email"
                placeholder="Email example@gmail.com"
                value={formData.USR_Email}
                onChange={handleChange}
                aria-label="Email"
              />
            </div>
            <div className="col-12">
              <PhoneInput
                className="form-design"
                country={"us"}
                value={formData.USR_Phone}
                enableSearch={true}
                onChange={handlePhoneChange}
                inputProps={{
                  name: "USR_Phone",
                  required: true,
                  autoFocus: false,
                  style: { color: "black" },
                }}
                searchPlaceholder="Search country"

              />
            </div>
         
          
            <div className="col-12">
              <input
                type="password"
                className="form-control second-page form-design"
                name="USR_Password"
                placeholder="Password"
                value={formData.USR_Password}
                onChange={handleChange}
                aria-label="Password"
              />
            </div>
            <div className="col-12">
              <input
                type="password"
                className="form-control second-page form-design"
                name="USR_Password_confirmation"
                placeholder="Confirm Password"
                value={formData.USR_Password_confirmation}
                onChange={handleChange}
                aria-label="Confirm Password"
              />
            </div>
       
          <div className="row mt-1">
            <Alert className="alert-message"
                message={
                  <Marquee pauseOnHover gradient={false}>
                    Your password must be at least 8 characters long, include one uppercase letter, one lowercase letter, one number, and one special character, or use a strong password suggested by Google Chrome or Microsoft Edge.                                 
                  </Marquee>
                }
            />
          </div>
        </>
      ),
    },
  ];

  const validateStep = () => {
    const { USR_First_Name, USR_Last_Name, USR_Username } = formData;
    if (!USR_First_Name || !USR_Last_Name || !USR_Username) {
      message.error("All fields in Personal Information are required.");
      return false;
    }

    const usernameRegex = /^[a-zA-Z0-9_]{3,20}$/;
    if (!usernameRegex.test(USR_Username)) {
      message.error(
        "Username must be 3-20 characters long and can only contain letters, numbers, and underscores."
      );
      return false;
    }

    return true;
  };

  const next = () => {
    if (current === steps.length - 1) {
      handleSubmit();
    } else {
      if (validateStep()) setCurrent(current + 1);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const passwordPatterns = [
    {
      regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{12,}$/
    },
    {
      regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{12,}$/
    },
    {
      regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/
    },
    {
      regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,}$/
    }
  ];


  const validatePassword = (password, confirmPassword) => {
    if (password !== confirmPassword) {
        message.error('Password does not match the confirmation');
        return false;
    }
    const matchedPattern = passwordPatterns.find(patternObj => patternObj.regex.test(password));
    if (!matchedPattern) {
        message.error('Your password does not meet the required criteria. Please include uppercase, lowercase, numbers, special characters, and ensure sufficient length.');
        return false;
    }
    return true;
  };

  const validateFormData = () => {
    const {
      USR_Username,
      USR_Password,
      USR_Email,
      USR_Phone,
      USR_Phone_country_code,
    } = formData;

    if (
      !USR_Username ||
      !USR_Password ||
      !USR_Email ||
      !USR_Phone ||
      !USR_Phone_country_code
    ) {
      message.error("All fields are required.");
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(USR_Email)) {
      message.error("Invalid email format.");
      return false;
    }

    const phoneRegex = /^[0-9]{10,15}$/;
    if (!phoneRegex.test(USR_Phone)) {
      message.error("Invalid phone number format.");
      return false;
    }

    return validatePassword(USR_Password, formData.USR_Password_confirmation);
  };

  const handleSubmit = async () => {
    if (!validateFormData()) return;

    const payload = {
      ...formData,
      USR_First_Last: `${formData.USR_First_Name} ${formData.USR_Last_Name}`,
      USR_Source: 1,
    };

    try {
      const result = await dispatch(registerUser(payload)); 
      if (result.payload.status === "success") {
        message.success(result.payload.message || "Registration successful");
        navigate(`/Home/verify`);
      }  else if (
        result.payload.message &&
        result.payload.message.includes('duplicate key value violates')
      ) {
        message.error('This email already exists');
      } else if (
        result.payload.error &&
        result.payload.error.includes('duplicate key value violates')
      ) {
        message.error('This email already exists');
      } else {
        message.error("Registration failed");
      }
    } catch (error) {
      message.error("Failed to register. Please try again.");
    }
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    const { credential } = credentialResponse;

    if (credential) {
      try {
        const resultAction = await dispatch(googleLogin(credential));
        if (googleLogin.fulfilled.match(resultAction)) {
          message.success('Google Login successful!');
          if (resultAction.payload.Flag === false) {
            navigate('/User/Onboarding');
          } else {
            navigate('/User');
          }
        } else {
          const errorMessage = resultAction.payload || 'Google login failed';
          message.error(errorMessage);
        }
      } catch (dispatchError) {
        message.error('Google login failed during the server request');
      }
    } else {
      message.error('Google login failed');
    }
  };

  return (
    <div className="register-section">
      <div className="container">
        <div className="row">
          <div className="col-5">
            <Lottie className="register-logo" animationData={registerLottie} />
          </div>
          <div className="col-7 form-desin-login register-design d-flex justify-content-center align-items-center">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (current === steps.length - 1) {
                  handleSubmit();
                } else {
                  next();
                }
              }}
              className="FormRegister"
            >
              <div className="text-center">
                <h2>Create Account</h2>
                <p>Create Your Account Now And Enjoy Our Service</p>
              </div>

              <Steps current={current} items={items} />
              <div className="register style-steps">
                {steps[current].content}
              </div>
              <div
                className="text-center btn-responsive"
                style={{ marginTop: 24 }}
              >
                {current > 0 && (
                  <button
                    type="button"
                    className="btn btn-previous-register "
                    onClick={prev}
                  >
                    Previous
                  </button>
                )}
                {current < steps.length - 1 && (
                  <button
                    type="button"
                    className="btn btn-next-register"
                    onClick={next}
                  >
                    Next
                  </button>
                )}
                {current === steps.length - 1 && (
                  <button
                    type="submit"
                    className="btn btn-done-register mt-2"
                    disabled={loading}
                  >
                    {loading ? "Creating Account..." : "Create Account"}
                  </button>
                )}
              </div>

              <Divider />

              <div className="row mt-3 justify-content-between align-items-center">
                <div className="col-4">
                  <p className="text-center">Or continue with</p>
                </div>
              </div>

              <div className="row google align-items-center  justify-content-center">
                <div className="col-12">
                  <GoogleLogin
                    onSuccess={handleGoogleSuccess}
                    onError={() => {
                      message.error("Google registration failed");
                    }}
                    useOneTap
                  />
                </div>
              </div>

              <span className="text-center d-flex  justify-content-center mt-3">
               <span> Already have an account?{" "}</span>
                <Link to="/Home/login">
                  <span className="span-route ">Login</span>
                </Link>
              </span>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
