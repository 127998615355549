import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

// Main Component
import App from '../App';

// Routing
import ProtectedRoute from './protectedroute';
import RedirectRoute from './redirectroute';

// Main Modules
import Landing from '../modules/Landing/Landing';
import Client from '../modules/Client/Client';

// Landing Pages
import Main from '../modules/Landing/Pages/Main.jsx';
import PrivacyPolicy from '../modules/Landing/Pages/PrivacyPolicy';
import TermsPage from '../modules/Landing/Pages/TermsPage';
import Plans from '../modules/Landing/Pages/Plans.jsx';

// Authentication Pages
import Register from '../modules/Landing/Pages/Register';
import VerifyPage from '../modules/Landing/Pages/VerifyPage';
import Login from '../modules/Landing/Pages/Login';
import ResetPage from '../modules/Landing/Pages/ResetPage';
import PasswordReset from '../modules/Landing/Pages/PasswordReset';

// Client Modules
import System from '../modules/Client/modules/System/System';
import Onboarding from '../modules/Client/modules/Onboarding/Onboarding';

// Setup Pages
import SetupMain from '../modules/Client/modules/System/Modules/Setup/Pages/SetupMain';
import QuestionnerPage from '../modules/Client/modules/System/Modules/Setup/Pages/QuestionnerPage';

// Report Pages
import FinancialReports from '../modules/Client/modules/System/Modules/Report/Pages/FinancialReports.jsx';
import ReportPage from '../modules/Client/modules/System/Modules/Report/Pages/ReportPage.jsx';

// Error Page
import ErrorPage from '../modules/Landing/Pages/ErrorPage.jsx';
import ScreenShot from '../Components/ScreenShot';
import UserSettings from '../modules/Client/modules/System/Modules/UserSetting/UserAccount.jsx';
import CombinedFinancialReports from '../modules/Client/modules/System/Modules/Finance-Report/Pages/CombinedFinancialReports.jsx';

// System Modules
const Dashboard = lazy(() => import('../modules/Client/modules/System/Modules/Dashboard/Dashboard'));
const Setup = lazy(() => import('../modules/Client/modules/System/Modules/Setup/Setup'));
const Report = lazy(() => import('../modules/Client/modules/System/Modules/Report/Report'));

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ScreenShot>
        <App />
      </ScreenShot>
    ),
    children: [
      {
        index: true,
        element: <RedirectRoute />,
      },
      {
        path: 'Home',
        element: (
          <ProtectedRoute>
            <Landing />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute>
                <Main />
              </ProtectedRoute>
            ),
          },
          {
            path: 'register',
            element: (
              <ProtectedRoute>
                <Register />
              </ProtectedRoute>
            ),
          },
          {
            path: 'verify',
            element: <VerifyPage />,
          },
          {
            path: 'login',
            element: (
              <ProtectedRoute>
                <Login />
              </ProtectedRoute>
            ),
          },
          {
            path: 'reset',
            element: (
              <ProtectedRoute>
                <ResetPage />
              </ProtectedRoute>
            ),
          },
          {
            path: 'password-reset/:token',
            element: (
              <ProtectedRoute>
                <PasswordReset />
              </ProtectedRoute>
            ),
          },
          {
            path: 'privacy-and-policy',
            element: (
              <ProtectedRoute>
                <PrivacyPolicy />
              </ProtectedRoute>
            ),
          },
          {
            path: 'terms-and-conditions',
            element: (
              <ProtectedRoute>
                <TermsPage />
              </ProtectedRoute>
            ),
          },
          {
            path: 'plans',
            element: (
              <ProtectedRoute>
                <Plans />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'User',
        element: (
          <Client />
        ),
        children: [
          {
            element: (
              <ProtectedRoute>
                <System />
              </ProtectedRoute>
            ),
            children: [
              {
                index: true,
                element: <Navigate to="/User/Dashboard" />,
              },
              {
                path: 'Dashboard',
                element: (
                  <ProtectedRoute>
                    <Suspense fallback={<></>}>
                      <Dashboard />
                    </Suspense>
                  </ProtectedRoute>
                ),
                children: []
              },
              {
                path: 'settings',
                element: (
                  <ProtectedRoute>
                    <Suspense fallback={<></>}>
                      <UserSettings />
                    </Suspense>
                  </ProtectedRoute>
                ),
                children: []
              },
              {
                path: 'finance/Reports',
                element: (
                  <ProtectedRoute>
                    <Suspense fallback={<></>}>
                      <CombinedFinancialReports />
                    </Suspense>
                  </ProtectedRoute>
                ),
                children: []
              },
              {
                path: 'Financials-setup',
                element: (
                  <ProtectedRoute>
                    <Suspense fallback={<></>}>
                      <Setup />
                    </Suspense>
                  </ProtectedRoute>
                ),
                children: [
                  {
                    index: true,
                    element: <SetupMain />,
                  },
                  {
                    path: ':title',
                    element: <QuestionnerPage />,
                  }
                ]
              },
              {
                path: 'Reports',
                element: (
                  <ProtectedRoute>
                    <Suspense fallback={<></>}>
                      <Report />
                    </Suspense>
                  </ProtectedRoute>
                ),
                children: [
                  {
                    index: true,
                    element: <FinancialReports />,
                  },
                  {
                    path: ':title',
                    element: <ReportPage />,
                  }
                ]
              }
            ]
          },
          {
            path: 'Onboarding',
            element: (
              <Onboarding />
            ),
          },
        ]
      }
    ]
  },
  {
    path: '*',
    element: <ErrorPage />,
  },
]);

export default router;