// Libraries
import React from "react";
import moment from "moment";
import { DatePicker } from "antd";

// Tools
import VariableInput from "../Common/VariableInput";

// Context
import { useSetupContext } from "../../Context/index";

const FinanceInputTool = ({ Index, Question, Responses }) => {
  // Context
  const { handleCurrentDropdownResponse } = useSetupContext();

  // Functions
  const handleInput = (field, value) => {
    let Response = Responses
      ? structuredClone(Responses)
      : {
        Response: { Value: "", Type: "", Date: null },
        VariableInputFlag: false,
      };

    if (field === "Date") {
      value = value ? value.toISOString() : null;
    }

    Response.Response[field] = value;
    handleCurrentDropdownResponse(Index, Response);
  };

  const handleVariable = () => {
    let Response = Responses
      ? structuredClone(Responses)
      : {
        Response: { Value: "", Type: "", Date: null },
        VariableInputFlag: false,
      };

    Response.VariableInputFlag = !Response.VariableInputFlag;
    if (Response.VariableInputFlag) {
      Response.Response = [];
    } else {
      Response.Response = { Value: "", Type: "", Date: null };
    }

    handleCurrentDropdownResponse(Index, Response);
  };

  const handleInputVariable = (value) => {
    let Response = { ...Responses };
    if (!Response) {
      Response = {
        Response: [],
        VariableInputFlag: true,
      };
    }

    Response.Response = value;
    handleCurrentDropdownResponse(Index, Response);
  };

  return (
    <div className="d-flex align-items-center  gap-2">
      {!Responses?.VariableInputFlag && (
        <div className="d-flex w-full align-items-center mb-2 gap-1">
          <div className="d-flex  align-items-center  input-div">
            <span
              className="flex items-center bg-[#3D5A80] text-white px-2 py-1 rounded-l-md"
              style={{
                backgroundColor: "#3D5A80",
                color: "white",
                padding: "0.55rem",
                borderTopLeftRadius: "0.25rem",
                borderBottomLeftRadius: "0.25rem",
              }}
            >
              $
            </span>
            <input
              type="number"
              inputMode="numeric"
              pattern="[0-9]*"
              value={Responses?.Response?.Value || ""}
              onChange={(e) => handleInput("Value", e.target.value)}
              placeholder={"100"}
              className=" w-32 px-3 py-2 border border-gray-300  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              required
            />
          </div>

          <div className="toggle-container">
            <button
              className={`toggle-button ${
                Responses?.Response?.Type === "Per Month" ? "active" : ""
              }`}
              onClick={() => handleInput("Type", "Per Month")}
            >
              Per Month
            </button>
            <button
              className={`toggle-button ${
                Responses?.Response?.Type === "Per Year" ? "active" : ""
              }`}
              onClick={() => handleInput("Type", "Per Year")}
            >
              Per Year
            </button>
          </div>

          {/* <div className="period-content">
            <div className="select-wrapper">
              <select
                className="custom-select select-padding"
                value={Responses?.Response?.Type || ""} onChange={(e) => handleInput("Type", e.target.value)} required
              >
              <option value="" disabled>Select a period</option>
                        <option value="Per Month">Per Month</option>
                        <option value="Per Year">Per Year</option>
              </select>
              <svg
                className="select-arrow"
                width="15"
                height="10"
                viewBox="0 0 15 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.72727 0L0 2.72727L7.27273 10L14.5455 2.72727L11.8182 0L7.27273 4.54545L2.72727 0Z"
                  fill="#EE6C4D"
                  fillOpacity="0.5"
                />
              </svg>
            </div>
          </div> */}

          <span
            className="flex items-center ml-3 mr-3 Q8-dollar-icon text-white px-2 py-1 rounded-l-md"
            style={{
              backgroundColor: "#3D5A80",
              color: "white",
              padding: "0.55rem",
              borderTopLeftRadius: "0.25rem",
              borderBottomLeftRadius: "0.25rem",
            }}
          >
            {" "}
            From{" "}
          </span>
          <DatePicker
            picker="month"
            value={Responses?.Response?.Date ? moment(Responses.Response.Date) : null}
            onChange={(date) => handleInput("Date", date)}
            className="w-full date-input appearance-none bg-white px-3 py-2 rounded-lg border transition-all duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            required
            placeholder="MM-YYYY"
            format="MM-YYYY"
          />
        </div>
      )}
      {Question.VariableEnable === "true" && (
        <div className="d-flex justify-content-center gap-2" style={{ display: "flex", flexDirection: "column" }}>
          <label className="toggle-switch">
            <input
              type="checkbox"
              checked={Responses?.VariableInputFlag || false}
              onChange={() =>  handleVariable()}
          
            />
            <span className="toggle-slider">
              <span className="toggle-knob" />
            </span>
            <span className="toggle-label">Variable Input</span>
          </label>

          {/* <div tyle={{display: "flex", flexDirection: "row"}}>
                            <div className="form-check form-switch p-0 mt-3">
                            
                            <input
                                id="flexSwitchCheckDefault"
                                type="checkbox"
                                className='form-check-input ms-0 me-1'
                                checked={Responses?.VariableInputFlag || false}
                                onChange={() => handleVariable()}
                                style={{marginLeft: "10px"}}
                            />
                            <label className='form-check-label' htmlFor="flexSwitchCheckDefault">Variable Input</label>
                            </div>
                        </div> */}

          {Responses?.VariableInputFlag && (
            <VariableInput
              Data={Responses?.Response}
              onDataChange={(value) => handleInputVariable(value)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default FinanceInputTool;
