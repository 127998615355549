// Libraries
import React, { useState, useEffect } from 'react'

// Context
import { useSetupContext } from '../../Context/index';

const VariableSelectionTool = () => {
  // Context
  const { currentQuestion, currentResponses, handleCurrentResponse, PushQuestions, PopQuestions } = useSetupContext();

  // Custom States
  const [Options, setOptions] = useState([]);

  const [Add, setAdd] = useState(false);
  const [UserInput, setUserInput] = useState("");

  // Functions
  const handleSelection = (response) => {
    let Response = Array.isArray(currentResponses) ? currentResponses : [];
    const existingResponseIndex = Response.findIndex((res) => res.choice === response);
    const optionIndex = Options.findIndex((option) => option.choice === response);


    const updatedResponse = existingResponseIndex > -1
      ? Response.map((res, index) => 
          index === existingResponseIndex ? { ...res, state: res.state === "active" ? "inactive" : "active" } : res
        )
      : [...Response, { choice: response, state: "active" }];

    
    if(optionIndex > -1) {
      if(currentQuestion.followuptype === "Repeated"){
        const ques = currentQuestion.followupQuestions.map((question) => ({
          ...question,
          Keyelement: response,
          ...(currentQuestion.Keyelement && { FactorKey: currentQuestion.Keyelement })
        }));

        if(Options[optionIndex].state === "active") {
          PopQuestions(ques);
        } else if ((Options[optionIndex].state === "inactive")) {
          PushQuestions(ques);
        }
      }

      setOptions(Options.map((option, index) =>
        index === optionIndex ? { ...option, state: option.state === "active" ? "inactive" : "active" } : option
      ));
    } else {
      const AddQuestions = currentQuestion?.followupQuestions?.map((question) => ({
        ...question,
        Keyelement: response,
        ...(currentQuestion.Keyelement && { FactorKey: currentQuestion.Keyelement })
      }));
      PushQuestions(AddQuestions);

      setOptions([...Options, { choice: response, state: "active" }]);
    }

    handleCurrentResponse(updatedResponse);
  };

  const handleAddOption = () => {
    handleSelection(UserInput);
    setAdd(false);
    setUserInput("");
  };

  useEffect(() => {
    const handleOptions = () => {
      setOptions([...currentResponses]);
    };

    handleOptions();
  },[currentQuestion]);

  return (
    <div className="d-flex w-100 flex-wrap gap-1">
      {Array.isArray(Options) && Options.map((option, index) => (
        <button  
          key={index}
          className={`btn btn-choice ${option.state === "active" ? "selected" : ""}`}
          onClick={() => handleSelection(option.choice)}
        >
          {option.choice}
        </button>
      ))}
      {currentQuestion?.UserEdit === "true" && (
        <>
          {Add ? (
            <div style={{ position: 'relative', width: '100%' }}>
              <input
                type="text"
                onChange={(e) => setUserInput(e.target.value)}
                placeholder="Enter your response"
                required
                style={{
                  border: '2px solid #EE6C4D',
                  width: '100%',
                  color: '#EE6C4D',
                  padding: '10px',
                  borderRadius: '32px',
                  height: '50px',
                  minWidth: '50px',
                  boxSizing: 'border-box',
                }}
              />
              <button
                onClick={() => setAdd(false)}
                style={{
                  position: 'absolute',
                  bottom: '10px',
                  right: '70px',
                  backgroundColor: 'transparent',
                  border: 'none',
                  color: '#EE6C4D',
                }}
              >
                Cancel
              </button>
              <button
                onClick={handleAddOption}
                style={{
                  position: 'absolute',
                  bottom: '10px',
                  right: '10px',
                  backgroundColor: 'transparent',
                  border: 'none',
                  color: '#EE6C4D',
                }}
              >
                Save
              </button>
            </div>
          ) : (
            <button
              className="btn btn-choice"
              onClick={() => setAdd(true)}
            >
              Add Option
            </button>
          )}
        </>
      )}
    </div>
  )
}

export default VariableSelectionTool