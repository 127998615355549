// Libraries
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

// CSS Components
import { Menu } from 'antd';
import { BadgeDollarSign, ChartNoAxesCombined, ChartSpline, House, User } from 'lucide-react';

const MediumScreenMenu = () => {
    const location = useLocation();
    const selectedKey = location.pathname;

    const getSelectedKey = () => {
        if (selectedKey.startsWith('/User/Financials-setup')) {
        return '/User/Financials-setup';
        }
        return selectedKey;
    };

    const items = [
        {
            key: '/User/dashboard',
            icon:     <House />,
            label: <Link to={`/User/dashboard`}>Home</Link>,
        },
        {
            key: '/User/Financials-setup',
            icon: <BadgeDollarSign />,
            label: <Link to={`/User/Financials-setup`}>Setup Assistant</Link>,
        },
        {
            key: '/User/Reports',
            icon: <ChartNoAxesCombined />,
            label: <Link to={`/User/Reports`}>Financial Data</Link>,
        },
        {
            key: '/User/finance/Reports',
            icon:     <ChartSpline />,
            label: <Link to={`/User/finance/Reports`}>Reports</Link>,
        },
        {
            key: '/User/settings',
            icon: <User />,
            label: <Link to={`/User/settings`}>Users</Link>,
        },
    ];
    return (
        <Menu
            className='left-slide-style'
            mode="inline"
            selectedKeys={[getSelectedKey()]}
            items={items}
        />
    );
};

export default MediumScreenMenu;