// Libraries
import React, { useState, useEffect } from 'react';

// Context
import { useOnboardingContext } from '../Context/index';

// CSS Components
import { Form, Input, message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const StepThree = () => {
  const [Categories, setCategories] = useState([]);
  const [loading , setLoading] = useState(false);
  const [aiSuggestions, setAISuggestions] = useState([]);

  const { formData, handleFormData, loadingAI, handleLoadSuggestions } = useOnboardingContext();
  const loadingIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

  const handleLoadAISuggestions = async () => {
    try {
      const result = await handleLoadSuggestions('Name');
      if (result.error) {
        message.error(result.error);
      } else {
        setAISuggestions(result);
      }
    } catch (error) {
      message.error(error.message || 'Error loading AI suggestions');
    }
  };

  useEffect(() => {
    const handleLoadAISuggestions = async () => {
      try {
        setLoading(true);
        const result = await handleLoadSuggestions('ai');
        if (result.error) {
          message.error(result.error);
        } else {
          setCategories(result);
        }
      } catch (error) {
        message.error(error.message || 'Error loading AI suggestions');
      } finally {
        setLoading(false);
      }
    };

    handleLoadAISuggestions();
  }, []);

  return (
    <div className="scrollable-buttons-container">
      <div className="text-start q1-onboarding mt-1">
        <h1 className="text-center">How would you like to categorize your business?</h1>
      </div>
      <div className="row">
        {loading ? (
          <div className="onboarding-loading-container">
            <Spin indicator={loadingIcon} />
          </div>
        )  : (
          Array.isArray(Categories) && Categories.length > 0 && (
            <div className="name-suggestions d-flex flex-wrap justify-content-center mt-3">
              {Categories.map((suggestion, index) => {
                const sanitizedSuggestion = suggestion.replace(/[^a-zA-Z\s]/g, '');
                return (
                  <div key={index} className="flex-item mb-3 d-flex justify-content-center">
                  <button
                    className={`btn btn-onBorading-Answers w-100 ${
                    formData.ONBOARDING_SELECTED_AiSUGGESTION === suggestion ? 'selected' : ''
                    }`}
                    onClick={() => handleFormData('ONBOARDING_SELECTED_AiSUGGESTION', suggestion)}
                  >
                    <h7>{sanitizedSuggestion}</h7>
                  </button>
                  </div>
                );
              })}
            </div>
          )
        )}
      </div>
      <div className="text-start q1-onboarding mt-1">
        <h1 className="text-center">What is the business called?</h1>
        <p>
          If you haven't chosen a name, enter a temporary one and change it later in the Business Settings.
        </p>
      </div>
      <div className="row">
        <div className="col-7">
          <Form.Item
            name="businessName"
            rules={[{ required: true, message: 'Please provide a business name' }]}
          >
            <Input
              value={formData.ONBOARDING_BUSSINES_NAME}
              onChange={(e) => handleFormData('ONBOARDING_BUSSINES_NAME', e.target.value)}
              placeholder="e.g. aallie"
              className="mt-4"
            />
          </Form.Item>
        </div>
        <div className="col-1 text-center pt-1">Or</div>
          <div className="col-4">
            <button className="btn btn-suggest w-100" onClick={handleLoadAISuggestions}>
              {loadingAI ? 
                <div className="ai-onboarding-loading-container" >
                  <Spin indicator={loadingIcon} />
                </div>
                : 'Suggest Name'
              }
            </button>
          </div>
          {Array.isArray(aiSuggestions) && aiSuggestions.length > 0 && (
            <div className="name-suggestions d-flex flex-wrap justify-content-center mt-3">
              {aiSuggestions.map((suggestion, index) => {
                const sanitizedSuggestion = suggestion.replace(/[^a-zA-Z\s]/g, '');
                return (
                  <div key={index} className="flex-item mb-3 d-flex justify-content-center">
                    <button
                      className={`btn btn-onBorading-Answers w-100 ${
                        formData.ONBOARDING_BUSSINES_NAME === suggestion ? 'selected' : ''
                      }`}
                      onClick={() => handleFormData('ONBOARDING_BUSSINES_NAME', suggestion)}
                    >
                      <h7>{sanitizedSuggestion}</h7>
                    </button>
                  </div>
                );
              })}
            </div>
          )}
      </div>
    </div>
  );
};

export default StepThree;
