// Libraries
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import axios from 'axios';

// Context
import { useOnboardingContext } from '../Context/index';

// Styles
import '../../../../../assets/styles/components/onBoarding.scss';
import questionerLottie from '../../../../../assets/lottie/questioner.json';

// Components
import { message, Steps } from 'antd';
import StepOne from '../Containers/StepOne';
import StepTwo from '../Containers/StepTwo';
import StepThree from '../Containers/StepThree';

const Questionner = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const { formData, handleSubmitQ } = useOnboardingContext();

  const nextStep = () => setCurrentStep((prev) => prev + 1);
  const prevStep = () => setCurrentStep((prev) => prev - 1);

  const navigate = useNavigate();

  const steps = [
    {
      content: <StepOne />,
    },
    {
      content: <StepTwo />,
    },
    {
      content: <StepThree />,
    },
  ];

  const items = steps.map((item, index) => ({
    key: `step-${index}`,
  }));

  const handleSubmitQuestionner = async () => {
    try {
      // Store the creation date in local storage
      localStorage.setItem('ONBOARDING_DATE', new Date().toLocaleString());
      setLoading(true);

      // Create a copy of formData to sanitize
      const sanitizedFormData = { ...formData };

      if (sanitizedFormData.ONBOARDING_SELECTED_AiSUGGESTION) {
        sanitizedFormData.ONBOARDING_SELECTED_AiSUGGESTION =
        sanitizedFormData.ONBOARDING_SELECTED_AiSUGGESTION.replace(/[^a-zA-Z\s]/g, '');
      }
      if (sanitizedFormData.ONBOARDING_BUSSINES_NAME) {
        sanitizedFormData.ONBOARDING_BUSSINES_NAME =
        sanitizedFormData.ONBOARDING_BUSSINES_NAME.replace(/[^a-zA-Z\s]/g, '');
      }
      if (sanitizedFormData.ONBOARDING_BUSSINES_DES) {
        sanitizedFormData.ONBOARDING_BUSSINES_DES =
        sanitizedFormData.ONBOARDING_BUSSINES_DES.replace(/[^a-zA-Z\s]/g, '');
      }

      // Now submit using the sanitized data
      // (assuming handleSubmitQ accepts the form data as a parameter)
      const result = await handleSubmitQ(sanitizedFormData);

      if (result.status) {
        message.success(result.message || 'Questionner submitted successfully');
        navigate('/User/dashboard');
      } else {
        message.error(result.error || 'Error submitting questionner');
      }
    } catch (error) {
      message.error(error.message || 'Error submitting questionner');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="onBoardingMainStyle">
      <div className="container p-5">
        <div className="row">
          <div className="col-5">
            <Lottie className="register-logo" animationData={questionerLottie} />
          </div>
          <div className="col-7">
            <Steps current={currentStep} items={items} />
            <div>{steps[currentStep].content}</div>
            <div className="button-container m-0">
              {currentStep > 0 && (
                <button className="btn btn-previous m-2 mt-3" onClick={prevStep}>
                  Previous
                </button>
              )}

              {currentStep < steps.length - 1 && (
                <button
                  className="btn btn-next m-2 mt-3"
                  onClick={nextStep}
                  disabled={currentStep === 0 && !formData.ONBOARDING_SELECTED_OPTION}
                >
                  Next
                </button>
              )}

              {currentStep === steps.length - 1 && (
                <button
                  className="btn btn-done m-2 mt-3"
                  onClick={handleSubmitQuestionner}
                  disabled={
                    loading ||
                    Object.entries(formData)
                      .filter(([key]) => key !== 'ONBOARDING_SELECTED_AiSUGGESTION')
                      .some(([, value]) => !value)
                  }
                >
                  {loading ? 'Submitting...' : 'Submit'}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questionner;
