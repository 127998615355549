// Libraries
import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { GoogleLogin } from '@react-oauth/google'
import { useDispatch, useSelector } from 'react-redux'

// CSS Libraries
import Lottie from 'lottie-react'
import { message, Spin } from 'antd'

// Styles
import '../../../assets/styles/pages/login.scss'
import loginLottie from '../../../assets/lottie/Login.json'

// Redux
import { googleLogin, loginUser } from '../../../Store/Features/authSlice'

const Login = () => {
  const { loading, error } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [loginData, setLoginData] = useState({
    USR_Email: '',
    USR_Password: ''
  })

  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value })
  }

  const handleLoginSuccess =  () => {
    message.success('Login successful!');
    navigate('/User');
  }

  const handleLogin = async (e) => {
    e.preventDefault()
    if (!loginData.USR_Email || !loginData.USR_Password) {
      message.error('Please fill in all fields')
      return
    }
    try {
      const resultAction = await dispatch(loginUser(loginData))
      if (loginUser.fulfilled.match(resultAction)) {
        handleLoginSuccess();
      } else {
        message.error(error || 'Incorrect email or password')
      }
    } catch (err) {
      message.error(err.message || 'Error during login')
    }
  }

  const handleGoogleSuccess = async (credentialResponse) => {
    const { credential } = credentialResponse
    if (credential) {
      try {
        const resultAction = await dispatch(googleLogin(credential))
        if (googleLogin.fulfilled.match(resultAction)) {
          message.success('Google Login successful!');
          if (resultAction.payload.Flag === false) {
            navigate('/User/Onboarding');
          } else {
            navigate('/User');
          }
        } else {
          const errorMessage = resultAction.payload || 'Google login failed'
          message.error(errorMessage)
        }
      } catch (err) {
        message.error('Google login failed during the server request')
      }
    } else {
      message.error('Google login failed')
    }
  }

  return (
    <div className="login-section">
      <div className="container login-container">
        <div className="row">
          <div className="col-12 col-lg-6 Login-design d-flex justify-content-center align-items-center">
            <div className="login-form-wrapper">
              <h2>SIGN IN WITH YOUR AALLIE ACCOUNT</h2>
              <form className="mt-3 form-desin-login" onSubmit={handleLogin}>
                <div className="mb-3">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="USR_Email"
                    value={loginData.USR_Email}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    name="USR_Password"
                    value={loginData.USR_Password}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12 mt-3">
                  {loading ? (
                    <Spin />
                  ) : (
                    <button type="submit" className="btn btn-submit-login w-100">
                      Login
                    </button>
                  )}
                </div>
              </form>
             <div className='d-flex justify-content-center mt-3'>
             <div>
                <Link to="/Home/Reset" className="span-route ">Forgot Password?</Link>
              </div>
             </div>
              <div className="row mt-3 or-section">
                <div className="col-12 d-flex align-items-center justify-content-center">
                  <div className="line1 flex-grow-1" />
                  <div className="or-text mx-3">Or continue with</div>
                  <div className="line1 flex-grow-1" />
                </div>
              </div>
              <div className="row mt-3 justify-content-center">
                <div className="col-auto">
                  <GoogleLogin
                    onSuccess={handleGoogleSuccess}
                    onError={() => {
                      message.error('Google login failed')
                    }}
                    useOneTap
                  />
                </div>
              </div>
              <div className="row mt-3  justify-content-center">
                <div className="col-auto gap-2 w-full d-flex align-items-center">
                  <span >Don't have an account?</span>
                  <Link to="/Home/register" className="span-route">Sign UP</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
            <Lottie className="login-logo" animationData={loginLottie} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
