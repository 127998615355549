// Libraries
import React, { useState } from 'react';
import { message, Spin } from "antd";
import axios from 'axios';

// Styles
import '../../../assets/styles/pages/contactUs.scss';

const ContactUs = () => {
    const [Data, setData] = useState({
        Name: '',
        Email: '',
        Message: ''
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const [loading, setLoading] = useState(false);

    const validate = () => {
        const { Name, Email, Message } = Data;

        if (!Name) {
            message.error('Name is required');
            return false;
        }
        if (!Email) {
            message.error('Email is required');
            return false;
        }
        if (!Message) {
            message.error('Message is required');
            return false;
        }

        return true;
    };

    const HanldeContact = async (e) => {
        try{
            e.preventDefault();
            if (!validate()) return;

            setLoading(true);
            const apiURL = `${process.env.REACT_APP_API_BASE_URL}/contactus`;

            const response = await axios.post(apiURL, Data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = response.data;
            if (data.status === 'success') {
                message.success(data.message);
                setData({
                    Name: '',
                    Email: '',
                    Message: ''
                });
            } else {
                message.error('Something went wrong, Please try again later');
            }
        } catch (error) {
            message.error('Something went wrong, Please try again later');
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='contactUs-page-style d-flex align-items-center justify-content-center'>
            <div className="container text-center">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-12 p-5">
                        <div className='contact-data'>
                            <h3>We’d love to hear from you.</h3>
                            <p>aallie® is here to provide you with more information, answer any questions you may have and create an effective solution for your instructional needs.</p>
                        </div>
                        <form className="d-flex flex-column align-items-center" onSubmit={HanldeContact}>
                            <div className="row form-design w-100">
                                <div className="col-12 col-md-6 mt-3 mt-md-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="Name"
                                        placeholder="Name"
                                        value={Data.Name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-12 col-md-6 mt-3 mt-md-0">
                                    <input
                                        type="email"
                                        className="form-control"
                                        name="Email"
                                        placeholder="Email"
                                        value={Data.Email}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3 form-design w-100">
                                <div className="form-group w-100">
                                    <textarea
                                        className="form-control"
                                        id="exampleFormControlTextarea1"
                                        rows="3"
                                        name="Message"
                                        placeholder="Message"
                                        value={Data.Message}
                                        onChange={handleChange}
                                    ></textarea>
                                </div>
                            </div>
                            <button className="btn btn-form-submit mt-3">
                                {loading ? <Spin /> : 'Send Message'}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
