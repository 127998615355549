import React from 'react';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import '../../../assets/styles/pages/home.scss';

// Example data
import user from '../../../assets/lottie/profile.json';
import AI from '../../../assets/lottie/AI2.json';
import support from '../../../assets/lottie/support.json';

const cardData = [
  {
    id: 1,
    animationData: AI,
    title: 'AI-Powered Forecasting',
  },
  {
    id: 2,
    animationData: support,
    title: 'Cost Breakdown & Scenarios',
  },
  {
    id: 3,
    animationData: user,
    title: 'Entrepreneur-Friendly Interface',
  },
];

const Home = () => {
  return (
    <div className="home">
      <div className="container">

        {/* Example "hero" area */}
        <div className="d-flex min-vh-100 justify-content-center align-items-center">
          <div className="home-content text-center px-3">
            <h1 className="responsive-heading">
              CAN YOUR IDEA <br /> MAKE MONEY?
            </h1>
            <p className="responsive-text mx-auto">
              Simplify Business Financial Planning. Amplify Growth.
              Aallie helps you forecast, plan, and adapt your financials so you can focus on building your dream business.
            </p>
            <div className="d-flex justify-content-center">
              <Link to="/Home/register">
                <button className="btn btn-try-now">Try it now</button>
              </Link>
            </div>
          </div>
        </div>

        {/* Cards Section */}
        <div className="cards text-center m-auto mb-3">
          <div className="row">
            {cardData.map((card) => (
              <div key={card.id} className="col-12 col-md-4">
                <div className="card mb-3">
                  {/* Changed: replace row/no-gutters with d-flex to keep items side by side */}
                  <div className="d-flex align-items-center flex-nowrap">
                    <div className="col-4 col-md-3">
                      <Lottie
                        className="correct-logo p-3"
                        animationData={card.animationData}
                      />
                    </div>
                    <div className="col-8 col-md-9">
                      <div className="card-body">
                        <h5 className="card-title text-start p-1">
                          {card.title}
                        </h5>
                      </div>
                    </div>
                  </div>
                  {/* end changed section */}
                </div>
              </div>
            ))}
          </div>
        </div>

      </div>
    </div>
  );
};

export default Home;
