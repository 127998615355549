// Libraries
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {jwtDecode} from 'jwt-decode';

// Initial State
const initialState = {
  UserData: null,
  loading: false,
  error: null,
};

// Thunk Functions

export const registerUser = createAsyncThunk(
  'register/registerUser',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/register`, userData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Registration failed');
    }
  }
);

export const loginUser = createAsyncThunk(
  "auth/login",
  async (loginData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/login`, loginData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || "Error during login");
    }
  }
);

export const googleLogin = createAsyncThunk('auth/googleLogin', async (credential, { rejectWithValue }) => {
  try {
    const decoded = jwtDecode(credential);
    const { email, name, sub: googleId } = decoded;

    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/google-login`, {
      email,
      name,
      google_id: googleId,
    });

    return response.data; // Return the token or user data
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || 'Google login failed');
  }
});

export const fetchUserDetails = createAsyncThunk('auth/fetchUserDetails', async (_, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('Token');
    
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/user-details`, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
    });

    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || 'Error fetching user details');
  }
});


// Slice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(registerUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(registerUser.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload.Data;
      localStorage.setItem("Token", action.payload.token);
    })
    .addCase(registerUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || 'Error during registration';
    })
    .addCase(loginUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(loginUser.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload.Data;
      localStorage.setItem("Token", action.payload.token);
    })
    .addCase(loginUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(googleLogin.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(googleLogin.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload.Data;
      localStorage.setItem("Token", action.payload.token);
    })
    .addCase(googleLogin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(fetchUserDetails.fulfilled, (state, action) => {
      state.user = action.payload.Data;
    })
    .addCase(fetchUserDetails.rejected, (state, action) => {
      state.user = null;
    })
  },
});

// Actions
export const { setUser, setToken } = authSlice.actions;
export default authSlice.reducer;
