// Libraries
import React from 'react';

// Components
import PlansCard from '../Components/PlansCard';

const Plans = () => {
  return (
    <section>
      <PlansCard />
    </section>
  );
};

export default Plans;
